import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import DynamicForm from '../../components/dynamicForm/dynamicForm'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router-dom"
import { useUser } from '../../_common/hooks/authHook'
import { useUsecase } from '../../_common/hooks/usecaseHook'
import { URLS } from '../../_config'
import TitleBar from '../../components/titleBar/TitleBar'
import Timer from '../../components/timer/Timer'


export const IdeaSubmit = () => {

	const userApi = useUserApi();
	const navigate = useNavigate()
	const user: any = useUser();

	const { id } = useParams();
	const [caseDetails, setCaseDetails] = useState<any>(null);
	const [answerID, setAnswerID] = useState<any>(null);

	const usecase: any = useUsecase();

	const getUserCaseInfo = () => {
		userApi.useCaseDetails({ urlParams: {companyID:user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
			setCaseDetails(resp.data);
			setAnswerID(resp.data.fkFormAnsweID)
		}, (message: any) => { })
	}

	console.log('user==', user)
	useEffect(() => {
		if (usecase && id) {
			if (usecase.useCaseID != id) {
				getUserCaseInfo();
			} else {
				setCaseDetails(usecase);
				setAnswerID(usecase.fkFormAnsweID)
			}
		} else {
			if (id) {
				getUserCaseInfo();
			}
		}
	}, [usecase, id])

	return (

		<React.Fragment>
			<Header></Header>
			<div className="main-container flex-grow-1">
				<div className="container">

					<TitleBar title={'Submit your Idea'} breadcrumbs={{
						'Use case list': URLS.USE_CASE_LIST,
						'Submit Idea': '#'
					}} >
						<Timer startTime={new Date()} />
					</TitleBar>

					<div className="row">
						<div className="col-sm-8">
							<div className="white-box">
								<DynamicForm useCaseID={id ? id : 0} editMode={answerID != null} answerID={answerID} _workflow='use-case' _module='create-use-case' _form='Usecase intake form' defaultData={{ Name: user?.userProfileDisplayName }} onSubmitSuccess={(edit) => { getUserCaseInfo(); navigate(URLS.USE_CASE_LIST) }} />
							</div>
						</div>
						<div className="col-sm-4">
							<div className="pt-5">
								<img src="../images/idea-pic.png" alt="" />
							</div>
						</div>
					</div>
				</div>

			</div>
			<Footer></Footer>
		</React.Fragment>

	)
}
