import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TitleBar from '../../components/titleBar/TitleBar';
import Error from "../../components/elements/formFieldError";
import { Button } from "react-bootstrap";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { useForm, SubmitHandler, useFieldArray, Controller } from "react-hook-form"
import { useFormApi } from "../../_common/hooks/api/FormApiHook";
import { useUser } from "../../_common/hooks/authHook";
import { ACTIONS, SAGA_ACTIONS } from '../../_config/index'
import Select from 'react-select'
import CreateControl from "../../components/form/createControl";
import { useControlTypes, useControlsProps } from "../../_common/hooks/formHook";
import { useDispatch } from "react-redux";







export const ManageForms = () => {

    const user:any = useUser();
    const navigate = useNavigate();
    const formApi = useFormApi();

    //const [controlTypes,setControlTypes] = useState<any[]>([])

    const [workflows,setWorkflows] = useState<any[]>([])
    const [modullies,setModullies] = useState<any[]>([])
    const [forms,setForms] = useState<any[]>([])

    const [isEdit,setIsEdit] = useState<boolean>(false);
    const dispatch = useDispatch()
    const controlTypes:any = useControlTypes();
    const controlsProps:any = useControlsProps();
   

    const {
        control,
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        formState: { errors },
    } = useForm<any>({defaultValues:{
        workflow:null,
        module:null,
        form:null,
        layout:null
    }})

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "field", 
    });
    const workflowWatcher = watch('workflow')
    const moduleWatcher = watch('module')
    const formWatcher = watch('form')
    const layoutWatcher = watch('layout')

    const controlPropMap:any = {
        TextBox:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan'
        },
        DatePicker:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'multiSelect',
            "colSpan": 'ColSpan'
        },
        TextArea:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan',
            "row": 'Row',
            "column": 'Column',
            "maxLength": 'MaxLength',
        },
        Dropdown:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'MultiSelect',
            "colSpan": 'ColSpan'
        },
        CheckBox:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'MultiSelect',
            "colSpan": 'ColSpan'
        },
        Radio:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'MultiSelect',
            "colSpan": 'ColSpan'
        },
        FileField:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'MultiSelect',
            "colSpan": 'ColSpan'
        },
        Label:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan'
        },
        HeadLine:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan',
            "action":'Action'
        },
        CustomControl:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan',
            "action":'Action'
        },
        Button:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan',
            "action":'Action'
        },
        Submit:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan',
            "action":'Action'
        }
    }

    const getControlTypes = () => {
        formApi.call(SAGA_ACTIONS.FORM.GET_CONTROL_TYPES, {urlParams:{PageIndex:0,PageSize:999}}, (message: any, resp: any) => {
  
        }, (message: any, resp: any) => {

        })
    }

    const getControlProps = (ControlTypeID:any)=>{
        formApi.call(SAGA_ACTIONS.FORM.GET_CONTROL_PROPS, {urlParams:{ControlTypeID:ControlTypeID}}, (message: any, resp: any) => {
            let data:any = {}
            data[`id-${ControlTypeID}`] = resp.data.controlTypeProperties
            dispatch({
                type: ACTIONS.FORM.CONTROLS_PROPS, payload: {
                    controlsProps: {...controlsProps,...data}
                }
            })
        }, (message: any, resp: any) => {})
    }
    const getWorkFlows = () => {
        formApi.call(SAGA_ACTIONS.FORM.GET_WORKFLOWS, {urlParams:{companyID:user.companyID}}, (message: any, resp: any) => {
            setWorkflows(resp.map((flow:any)=>{
                flow.value = flow.workFlowID
                flow.label = flow.title
                return flow
            }))
        }, (message: any, resp: any) => {
        })
    }
    const getModules = (workFlowID:any) => {
        formApi.call(SAGA_ACTIONS.FORM.GET_MODULES, {urlParams:{WorkFowID:workFlowID,companyID:user.companyID}}, (message: any, resp: any) => {
            setModullies(resp.data.moduleIs.map((mod:any)=>{
                mod.value = mod.moduleID;
                mod.label = mod.pageTitle;
                return mod
            }))
        }, (message: any, resp: any) => {

        })
    }
    const getForms = (moduleID:any) => {
        formApi.call(SAGA_ACTIONS.FORM.GET_FORMS, {urlParams:{ModuleID:moduleID,companyID:user.companyID}}, (message: any, resp: any) => {
            setForms([{...resp.data,value:resp.data.fromID,label:resp.data.title}])
        }, (message: any, resp: any) => {

        })
    }

    const addNewField = ()=>{
        append({
            fkFormControlID:0
        })
    }

    const submitFormConfigData = (data: any) => {
        return new Promise((resolve, reject) => {
            formApi.call(SAGA_ACTIONS.FORM.SAVE, data, (message: any, resp: any) => {
                resolve(message)
            }, (message: any, resp: any) => {
                reject(message)
            })
        })
    }

    const getSelected = (value:any,node:any,options:any)=>{
        let res = null;
        options.map((option:any)=>{
            if(value == option[node]){
                res = option
            }
        });

        return res;
    }

    useEffect(()=>{
        if(formWatcher){
            remove()
            let opt = {value:1, col:12,label:'1 column'}
            if(formWatcher.layout.replace('Col:','') == 2) {
                opt = {value:2, col:6,label:'2 column'}
            }
            if(formWatcher.layout.replace('Col:','') == 3) {
                opt = {value:3, col:4,label:'3 column'}
            }
            setValue('layout',opt);

            
            if(formWatcher.controls.length){
                setIsEdit(true);// this form is in edit mode
                formWatcher.controls.map((control:any)=>{
                    let _data:any = {
                        controlType:getSelected(control.fkControlTypeID,'value',controlTypes),
                        fkFormControlID:control.fkFormControlID,
                        'isMandetoryControl':control.isMandetoryControl,
                        prop:{
                            'Caption':control.caption,
                            'PlaceHolder':control.placeHolder,
                            'ColSpan':{value:control.colSpan,label:control.colSpan},
                            'Required':control.required,
                            'MultiSelect':control.multiSelect,
                            
                        }
                    }

                    if(control.options){
                        _data.options = control.options
                    }

                    console.log('first time data => ',_data)
                    append(_data)
                })

                formWatcher.buttons.map((control:any)=>{
                    let _data:any = {
                        controlType:getSelected(control.fkControlTypeID,'value',controlTypes),
                        fkFormControlID:control.fkFormControlID,
                        prop:{
                            'Caption':control.caption,
                            'PlaceHolder':control.placeHolder,
                            'ColSpan':{value:control.colSpan,label:control.colSpan},
                            'Required':control.required
                        }
                    }
                    append(_data)
                })

            } else {
                setIsEdit(false);
            }
        } else {
            remove()
        }
    },[formWatcher])

    useEffect(()=>{
        getWorkFlows()
    }, [])
    useEffect(()=>{
        if(!controlTypes){
            getControlTypes()
        }
    }, [controlTypes])
    useEffect(()=>{
        if(controlTypes){
            let call = false;
            controlTypes.map((control:any)=>{
                if(!controlsProps[`id-${control.value}`] && !call){
                    getControlProps(control.value);
                    call = true
                }
            })
        }
    }, [controlTypes, controlsProps])


    const onSubmit: SubmitHandler<any> = (data) => {
        console.log('data =>', data)

        let params = {
            "fkCompanyID": user.companyID,
            "fkModuleID": data.module.moduleID,
            "fkWorkFlowID": data.workflow.workFlowID,
            "fkTenantID": user.tenantID,
            "userID": user.userID,
            "forms": [{
                "formsID": data.form.formID,
                "formcontrolDetails": data.field.map((_field:any,i:any)=>{
                    let data:any =  {
                        "controls": {
                            "fkControlTypeID": _field.controlType.controlTypeID,
                            "caption": _field.prop[controlPropMap[`${_field.controlType.controlName}`]['caption']],
                            "placeHolder":  _field.prop[controlPropMap[`${_field.controlType.controlName}`]['placeHolder']],
                            "displayOrder": i,
                            "isRequired":  _field.prop[controlPropMap[`${_field.controlType.controlName}`]['isRequired']],
                            "multiSelect":  _field.prop[controlPropMap[`${_field.controlType.controlName}`]['multiSelect']],
                            "text": _field.prop['Text']? ((typeof _field.prop['Text']) == 'object'?_field.prop['Text'].value:_field.prop['Text'])    :'',
                            "colSpan":  _field.prop[controlPropMap[`${_field.controlType.controlName}`]['colSpan']]?.value,
                            "FormControlID":_field.fkFormControlID
                        }
                    }

                    switch(_field.controlType.controlName){
                        case 'CheckBox' :
                        case 'Radio' :
                        case 'Dropdown' :
                            data.options =  _field.prop['Values'].options.map((opt:any)=>{
                                return {
                                    "fkControlTypeID": _field.controlType.controlTypeID,
                                      "optionKey": opt.value,
                                      "optionValue": opt.label
                                    }
                            })
                        break;
                        case 'Submit' :
                            data.controls.actionUrl =  _field.prop['ActionUrl'].value
                        break;
                        default :
                    }

                    

                    return data
                })
            }]
        }
        console.log('params=>', params);

        //return;

        toast.promise(
            submitFormConfigData(params), {
            loading: 'Saving...',
            success: (msg: any) => { reset(); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <TitleBar title={'Manage Forms'} breadcrumbs={{
                        'Manage Forms': '#'
                    }} ></TitleBar>

                    <div className="white-box">

                        <div className="form-area">
                            <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label htmlFor="tenant" className="form-label">Workflow</label>
                                            <Controller
                                                defaultValue=""
                                                control={control}
                                                name="workflow"
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field: { onChange, value, ref, name } }) => (
                                                    <div>
                                                        <Select
                                                            name={name}
                                                            onChange={(data) => {
                                                                onChange(data);
                                                                setModullies([])
                                                                setForms([])
                                                                setValue('module', null);
                                                                setValue('form', null);
                                                                getModules(data.value);
                                                            }}
                                                            value={value}
                                                            options={workflows}
                                                            isMulti={false}
                                                        />
                                                        <Error error={errors.workflow} />
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="tenant" className="form-label">Module</label>
                                            <Controller
                                                defaultValue=""
                                                control={control}
                                                name="module"
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field: { onChange, value, ref, name } }) => (
                                                    <div>
                                                        <Select
                                                            name={name}
                                                            onChange={(data) => {
                                                                onChange(data);
                                                                setForms([])
                                                                setValue('form', null);

                                                                getForms(data.value);
                                                            }}
                                                            value={value}
                                                            options={modullies}
                                                            isMulti={false}
                                                        />
                                                        <Error error={errors.module} />
                                                    </div>
                                                )}
                                            />



                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="tenant" className="form-label">Form</label>
                                            <Controller
                                                defaultValue=""
                                                control={control}
                                                name="form"
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field: { onChange, value, ref, name } }) => (
                                                    <div>
                                                        <Select
                                                            name={name}
                                                            onChange={onChange}
                                                            value={value}
                                                            options={forms}
                                                            isMulti={false}
                                                        />
                                                        <Error error={errors.form} />
                                                    </div>
                                                )}
                                            />



                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="company" className="form-label">Layout</label>
                                            <Controller
                                                defaultValue=""
                                                control={control}
                                                name="layout"
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field: { onChange, value, ref, name } }) => (
                                                    <div>
                                                        <Select
                                                            name={name}
                                                            onChange={onChange}
                                                            value={value}
                                                            options={[{ value: 1, col: 12, label: '1 column' }, { value: 2, col: 6, label: '2 column' }, { value: 3, col: 4, label: '3 column' }]}
                                                            isMulti={false}
                                                        />
                                                        <Error error={errors.form} />
                                                    </div>
                                                )}
                                            />
                                            {/* <Error error={errors?.fkCompanyID?.message} /> */}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        {workflowWatcher && moduleWatcher && formWatcher && layoutWatcher &&
                                            <React.Fragment>
                                                {fields.map((field: any, index) => (
                                                    <CreateControl
                                                        key={field.id}
                                                        index={index}
                                                        control={control}
                                                        register={register}
                                                        getValues={getValues}
                                                        setValue={setValue}
                                                        errors={errors}
                                                        controlTypes={controlTypes}
                                                        layout={layoutWatcher}
                                                        watch={watch}
                                                        field={field}
                                                        remove={remove}
                                                    />
                                                ))}

                                            </React.Fragment>
                                        }
                                    </div>
                                    {workflowWatcher && moduleWatcher && formWatcher && layoutWatcher

                                        ? <Button className="mt-3" onClick={addNewField}>Add New Field</Button> : null
                                    }

                                </div>
                                    <hr />
                                <div className="col-12 d-flex justify-content-end m-0">
                                    {!isEdit ?
                                        <Button type="submit" className="align-items-center d-flex gap-2">Submit <i className="arrow-right"></i></Button>
                                        :
                                        <Button type="submit" className="align-items-center d-flex gap-2">Update <i className="arrow-right"></i></Button>
                                    }
                                </div>
                                
                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}