import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import toast from 'react-hot-toast';
import { URLS } from "../../_config";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import { TbLockCog } from "react-icons/tb";
import { MdOutlineModeEdit } from "react-icons/md";
import { replaceUrlParams } from "../../_config/helpers";
import TableLoader from "../../components/tableLoader";
import Pagination from "../../components/pagination/Pagination";
import TitleBar from "../../components/titleBar/TitleBar";
import { Button } from "react-bootstrap";
import { ConfirmAlert } from "../../_common/confirmAlert";
import Icon from "../../components/icon";


const EmployeeList = () => {
    const userApi = useUserApi();
    const dispatch = useDispatch();
    const [employees, setEmployees] = useState<any>(null);
    const navigate = useNavigate();
    const user: any = useUser();
    //console.log('userInfo', userInfo)
    const permissions:any = useUserPermissions();

    const getEmployeeList = () => {
        userApi.employeeList({IsShowInactive:true}, (message: any, resp: any) => {
            setEmployees(resp.data.members);
        }, (message: any) => {
            setEmployees([])
        })

    }

    useEffect(() => {
        getEmployeeList()
    }, []);

    const submitData = (data: any) => {
        let formParam = {
            "userID": data.userID,
            "companyID": data.companyID,
            "isActive": data.isActive
        };

        return new Promise((resolve, reject) => {
            userApi.memberActivation([formParam], (message: any, resp: any) => {
                if (resp.success === true) {

                    resolve(resp.message);
                } else {
                    reject(resp.message);
                }
            }, (message: any, resp: any) => {
                // Error...
            })
        })
    }

    const statusChange = (status: boolean, userID: number, companyID: number) => {
        let msg = status === false ? 'In-Active' : 'Active';
        let data = {
            "userID": userID,
            "companyID": companyID,
            "isActive": status
        };

        Swal.fire({
            title: 'Confirm Status',
            text: `Are you sure you want to ${msg} this user?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, ${msg} it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    submitData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getEmployeeList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });


    }

    const sendInvitation = (params: any) => {
        return new Promise((resolve, reject) => {
            userApi.sendInvitation(params, resolve, reject)
        })
    }
    const sendInvitationHandler = (member: any) => {
        ConfirmAlert.invite(member.userProfileDisplayName, sendInvitation, [{
            "userID": member.userID,
            "addedBy": user.userID
        }])
    }

    const deleteEmployee = (params:any) => {
        return new Promise((resolve:any,reject:any)=>{
            userApi.deleteEmployee(params, resolve, reject)
        })
    }


    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={'Employee'} breadcrumbs={{
                        'Employee': '#'
                    }} >
                        {permissions?.menus.employee.write &&
                            <Link to="/employee/create" className="btn btn-info py-2 add-more-files-btn"> Add Employee</Link>
                        }
                    </TitleBar>

                    <div className="row">
                        <div className="col-sm-12"> 

                            <div className="white-box px-4 pt-3">
                                <div className="table-responsive list-table full-width">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="align-middle">
                                                <th></th>
                                                <th scope="col" className="text-left">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Role Type</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employees && employees.length > 0 && (
                                                employees.map((member: any) => (
                                                    <tr key={member.userID} className="align-middle h-60">
                                                        <td>
                                                            <img src={member.avatar} className="top-avatar" />
                                                        </td>
                                                        <td>{member.firstName}</td>
                                                        <td>{member.lastName}</td>
                                                        <td>{member.email}</td>
                                                        <td>{member.phone}</td>
                                                        <td>
                                                            <span className="badge bg-secondary">{member.roleName}</span>
                                                        </td>
                                                        <td className="w-200px">
                                                            <div className="d-flex gap-3 align-items-center">
                                                                {
                                                                    (member?.roleID != 1) &&
                                                                    <>
                                                                        {permissions?.menus.employee.write &&
                                                                            <>
                                                                                {member.isActive === true ? (
                                                                                    <Link to="#" onClick={() => { statusChange(false, member.userID, member.companyID) }}><i className="active-icon"></i></Link>
                                                                                ) : (
                                                                                    <Link to="#" onClick={() => { statusChange(true, member.userID, member.companyID) }}><i className="in-active"></i></Link>
                                                                                )}
                                                                                <Link to={replaceUrlParams(URLS.EMPLOYEE_EDIT, { id: member.userProfID })} className="react-icon-grey"><MdOutlineModeEdit /></Link>
                                                                                <Link to={replaceUrlParams(URLS.EMPLOYEE_ROLE_PERMISSION, { id: member.userProfID })} className="react-icon-grey" ><TbLockCog /> </Link>
                                                                                <Icon name="send-email" onClick={() => { sendInvitationHandler(member) }} />
                                                                            </>
                                                                        }

                                                                        {permissions?.menus.employee.delete &&
                                                                            <Icon name='delete' onClick={() => {
                                                                                ConfirmAlert.delete('Employee', deleteEmployee, {
                                                                                    "userID": member.userID,
                                                                                    "updatedBy": user.userID,
                                                                                }).then(() => {
                                                                                    getEmployeeList();
                                                                                })
                                                                            }}
                                                                            />
                                                                        }

                                                                    </>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                    <TableLoader data={employees} />
                                </div>

                                {/* <Pagination /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    );
}

export default EmployeeList;