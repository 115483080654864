/* let apiBaseUrl = 'https://api-fluxpay-v2-dev.dreamztesting.com/api/v1'
// let apiBaseUrl = 'http://10.25.26.134:8000/api/v1'
let hostName = '.dreamztesting.com'
const appStage = 'dev'//import.meta.env.REACT_APP_STAGE
if (appStage == 'dev') {
  apiBaseUrl = 'https://innovationforce-dev.dreamztesting.com/innovationsystem/api/v1'
  hostName = '.dreamztesting.com'
} else if (appStage == 'uat') {
  apiBaseUrl = 'https://api-fluxpay-v2-dev.dreamztesting.com/api/v1'
  hostName = '.dreamztesting.com'
} else if (appStage == 'prod') {
  //   apiBaseUrl = ''
}
//let apiBaseUrl='http://10.25.26.134:8000/api/v1' */

export const API_BASE_URL = process.env.REACT_APP_SYSTEM_API_URL
export const API_BUS_BASE_URL_ORIGIN = process.env.REACT_APP_BUS_ORIGIN
export const API_BUS_BASE_URL = process.env.REACT_APP_BUS_API_URL
export const VITE_CLERK_PUBLISHABLE_KEY = process.env.REACT_APP_VITE_CLERK_PUBLISHABLE_KEY
