/* eslint-disable @typescript-eslint/ban-types */
import { useApiCall } from '../common/appApiCallHook'
//import { BlankReq, LoginReq, LogoutReq } from '../../interfaces/ApiReqRes'
import { SAGA_ACTIONS } from '../../../_config/index'



export function useUserApi() {
  const callApi = useApiCall()



  const login = (data: any, onSuccess: Function, onError: Function) => {
    // console.log('data==>', data)
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError)
  }

  const loginSSO = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN_SSO, data, onSuccess, onError)
  }

  const getMemberDetails = (data: any, onSuccess: Function, onError: Function) => {
    // console.log('data==>', data)
    callApi(SAGA_ACTIONS.USER.GET_MEMBER_DETAILS, data, onSuccess, onError)
  }

  const logout = (data: any, onSuccess: Function, onError: Function) => {
    /*     onboardingAction.resetOnboarding()
        signupAction.resetSignup() */
    callApi(SAGA_ACTIONS.USER.LOGOUT, data, onSuccess, onError)
  }


  const forgotPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD, data, onSuccess, onError)
  }

  const resetPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RESET_PASSWORD, data, onSuccess, onError)
  }

  const employeeList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.EMPLOYEE_LIST, data, onSuccess, onError)
  }
  const deleteEmployee = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DELETE, data, onSuccess, onError)
  }
  const getFormConfig = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_FORM_CONFIG, data, onSuccess, onError)
  }
  const submitDynamicForm = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SUBMIT_DYNAMIC_FORM, data, onSuccess, onError)
  }

  const memberActivation = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.EMPLOYEE_ACTIVE_INACTIVE, data, onSuccess, onError)
  }
  const addEmployee = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ADD_EMPLOYEE, data, onSuccess, onError)
  }
  const sendInvitation = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SEND_INVITATION, data, onSuccess, onError)
  }

  const addRole = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ROLE_ADD, data, onSuccess, onError)
  }

  const editRole = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ROLE_EDIT, data, onSuccess, onError)
  }
  const detailsRole = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ROLE_DETAILS, data, onSuccess, onError)
  }

  const listRole = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ROLE_LIST, data, onSuccess, onError)
  }

  const deleteRole = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ROLE_DELETE, data, onSuccess, onError)
  }

  const useCaseList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USE_CASE_LIST, data, onSuccess, onError)
  }

  const useCaseDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USE_CASE_DETAILS, data, onSuccess, onError)
  }
  const deleteUsecase = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USE_CASE_DELETE, data, onSuccess, onError)
  }
  const changeAirportRunwayCaptains = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CHANGE_AIRPORT_RUNWAY_CAPTAINS, data, onSuccess, onError)
  }

  const airportList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.AIRPORT_LIST, data, onSuccess, onError)
  }

  const airportAdd = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.AIRPORT_ADD, data, onSuccess, onError)
  }

  const airportEdit = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.AIRPORT_EDIT, data, onSuccess, onError)
  }

  const airportDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.AIRPORT_DETAILS, data, onSuccess, onError)
  }

  const airportDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.AIRPORT_DELETE, data, onSuccess, onError)
  }

  const getUsersByRoleName = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.BY_ROLE_NAME, data, onSuccess, onError)
  }

  const getConfigByKey = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CONFIG_BY_ROLE_NAME, data, onSuccess, onError)
  }

  const getConfigByCompany = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CONFIG_BY_COMPANY_ID, data, onSuccess, onError)
  }

  const getWorkFlowStatusLookUpsAll = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.WORKFLOW_STATUS_LOOKUPS_ALL, data, onSuccess, onError)
  }

  const getRunwayByAirport = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_RUNWAY_BY_AIRPORT, data, onSuccess, onError)
  }

  const postUsecaseReviewData = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.POST_USECASE_REVIEW_DATA, data, onSuccess, onError)
  }

  const postUsecaseApproved = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.POST_USECASE_APPROVED, data, onSuccess, onError)
  }

  const setCaptainCoCaptain = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SET_CAPTAIN_CO_CAPTAIN, data, onSuccess, onError)
  }

  const postUsecaseDispproved = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.POST_USECASE_DISAPPROVED, data, onSuccess, onError)
  }

  const getUsecaseReviewList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_USECASE_REVIEW_LIST, data, onSuccess, onError)
  }



  const userDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USER_DETAILS, data, onSuccess, onError)
  }
  const userUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USER_UPDATE, data, onSuccess, onError)
  }
  const userChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USER_CHANGE_STATUS, data, onSuccess, onError)
  }
  const userRoleMap = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USER_ROLE_MAP, data, onSuccess, onError)
  }


  const getDiscovery = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DISCOVERY_GET_SINGLE, data, onSuccess, onError)
  }
  const getAllDiscovery = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DISCOVERY_GET_ALL, data, onSuccess, onError)
  }
  const deleteDiscoveryFile = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DISCOVERY_DELETE, data, onSuccess, onError)
  }
  const getChallengeStatementByUsecase = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USECASE_CHALLENGE_STATEMENT, data, onSuccess, onError)
  }
  const usecasePhaseChange = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USECASE_PHASE_CHANGE, data, onSuccess, onError)
  }


  const getDecisionByUsecase = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DECISION_GET, data, onSuccess, onError)
  }
  const decisionSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DECISION_SAVE, data, onSuccess, onError)
  }



  // /api/v{version}/Runway/getRunwayAll/{PageIndex}/{PageSize}
  const runwayList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RUNWAY.LIST, data, onSuccess, onError)
  }
  const runwaySave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RUNWAY.SAVE, data, onSuccess, onError)
  }
  const runwayUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RUNWAY.UPDATE, data, onSuccess, onError)
  }
  const runwayDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RUNWAY.DETAILS, data, onSuccess, onError)
  }
  const runwayDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RUNWAY.DELETE, data, onSuccess, onError)
  }
  const runwayChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RUNWAY.CHANGE_STATUS, data, onSuccess, onError)
  }


  const companyList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMPANY.LIST, data, onSuccess, onError)
  }

  const getTenantsAll = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TENANT.LIST, data, onSuccess, onError)
  }


  const solutionProviderList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.LIST, data, onSuccess, onError)
  }
  const solutionProviderSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.SAVE, data, onSuccess, onError)
  }
  const solutionProviderUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.UPDATE, data, onSuccess, onError)
  }
  const solutionProviderDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.DETAILS, data, onSuccess, onError)
  }
  const solutionProviderDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.DELETE, data, onSuccess, onError)
  }
  const solutionProviderChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.CHANGE_STATUS, data, onSuccess, onError)
  }


  const usecaseLike = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USECASE_LIKE, data, onSuccess, onError)
  }
  const getUsecaseCaptains = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_USECASE_CAPTAINS, data, onSuccess, onError)
  }

  const assignInnovationManagerToUsecase = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ASSIGN_INNOVATION_MANAGER_TO_USECASE, data, onSuccess, onError)
  }


  const menuPermissionGetByCompanyID = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.MENU_PERMISSION.GET_LIST, data, onSuccess, onError)
  }
  const menuPermissionSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.MENU_PERMISSION.SAVE, data, onSuccess, onError)
  }

  //=================== Dashboard =================//


  const dashboardSummeryCount = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DASHBOARD.SUMMERY_COUNT, data, onSuccess, onError)
  }
  const dashboardAvgDurationList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DASHBOARD.AVG_DURATION_LIST, data, onSuccess, onError)
  }
  const dashboardPhasePlan = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DASHBOARD.PHASE_PLAN, data, onSuccess, onError)
  }
  const dashboardPhaseAvg = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DASHBOARD.PHASE_AVG, data, onSuccess, onError)
  }


  const dashboardAvgTotalDuration = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DASHBOARD.AVG_TOTAL_DURATION, data, onSuccess, onError)
  }
  const dashboardAvgDurationBySection = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DASHBOARD.AVG_DURATION_BY_SECTION, data, onSuccess, onError)
  }
  const dashboardSectionPhaseTable = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DASHBOARD.SECTION_PHASE_TABLE, data, onSuccess, onError)
  }
  const dashboardAvgDurationPhase = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DASHBOARD.AVG_DURATION_PHASE, data, onSuccess, onError)
  }
  const getNotifications = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_NITIFICATIONS, data, onSuccess, onError)
  }
  const getNotificationDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_NITIFICATION_DETAILS, data, onSuccess, onError)
  }


  const notificationList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.NOTIFICATION.LIST, data, onSuccess, onError)
  }
  const notificationDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.NOTIFICATION.DETAILS, data, onSuccess, onError)
  }
  const notificationUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.NOTIFICATION.UPDATE, data, onSuccess, onError)
  }


  return {
    login,
    loginSSO,
    logout,
    forgotPassword,
    resetPassword,
    employeeList,
    deleteEmployee,
    getFormConfig,
    submitDynamicForm,
    memberActivation,
    getMemberDetails,
    addEmployee,
    sendInvitation,
    addRole,
    editRole,
    detailsRole,
    listRole,
    deleteRole,
    useCaseList,
    useCaseDetails,
    deleteUsecase,
    changeAirportRunwayCaptains,
    airportList,
    airportAdd,
    getUsersByRoleName,
    getConfigByKey,

    airportDetails,
    airportEdit,
    airportDelete,

    getWorkFlowStatusLookUpsAll,
    getRunwayByAirport,
    postUsecaseReviewData,
    getConfigByCompany,
    postUsecaseApproved,
    postUsecaseDispproved,
    getUsecaseReviewList,


    userDetails,
    userUpdate,
    userChangeStatus,
    userRoleMap,

    getDiscovery,
    getAllDiscovery,
    deleteDiscoveryFile,
    getChallengeStatementByUsecase,
    usecasePhaseChange,

    getDecisionByUsecase,
    decisionSave,

    runwayList,
    runwaySave,
    runwayUpdate,
    runwayDetails,
    runwayDelete,
    runwayChangeStatus,

    companyList,

    getTenantsAll,

    solutionProviderList,
    solutionProviderSave,
    solutionProviderUpdate,
    solutionProviderDetails,
    solutionProviderDelete,
    solutionProviderChangeStatus,
    usecaseLike,
    getUsecaseCaptains,
    assignInnovationManagerToUsecase,

    menuPermissionGetByCompanyID,
    menuPermissionSave,


    setCaptainCoCaptain,

    dashboardSummeryCount,
    dashboardAvgDurationList,
    dashboardPhasePlan,
    dashboardPhaseAvg, 

    dashboardAvgTotalDuration,
    dashboardAvgDurationBySection, 
    dashboardSectionPhaseTable,
    dashboardAvgDurationPhase,
    getNotifications,
    getNotificationDetails,
     
    notificationList,
    notificationDetails,
    notificationUpdate,
   
  }
}
