import { config } from "process"
import React, { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Error from "../elements/formFieldError"
import toast from "react-hot-toast"
import { useUserApi } from "../../_common/hooks/api/UserApiHook"
import { useUser } from "../../_common/hooks/authHook"
import ProductList from "../product/productList"
import { ACTIONS, SAGA_ACTIONS } from '../../_config/index'
import { useFormApi } from "../../_common/hooks/api/FormApiHook"
import { useMasterWorkflows } from "../../_common/hooks/masterHook"
import { useMasterApi } from "../../_common/hooks/api/MasterApiHook"
import { useDispatch } from "react-redux"
import { useControlTypes, useWorkflows } from "../../_common/hooks/formHook"
import Select from "react-select"
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsFileEarmarkPpt, BsFileEarmarkZip } from "react-icons/bs";
import { FaRegFile } from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa6";
import Loader from "../loader"
import { SolarPower } from "@mui/icons-material"
import Icon from "../icon"
import { Button } from "react-bootstrap"
import FilePreview from "../filePreview"
import { getSelectedValue } from "../../_config/helpers"
import { showDate } from "../../_config/functions"
import { useWorkFlowApi } from "../../_common/hooks/api/WorkFlowApiHook"

interface props {
    _workflow: string,
    _module: string,
    _form: string,
    useCaseID: any,
    onSubmitSuccess?: (edit: boolean) => void,
    editMode?: boolean,
    section?: string,
    phase?: string,
    answerID?: any,
    metadata?: any,
    defaultData?: any,
    _startTime?: Date | null,
    sded?: boolean | null,
}


export default function DynamicForm({ _workflow, _module, _form, useCaseID, onSubmitSuccess, editMode, section, phase, answerID, metadata = [], defaultData = {}, _startTime , sded}: props) {
    const { control, register, handleSubmit, reset, setValue, getValues, formState: { errors, isValid }, } = useForm()
    const form: any = useRef();
    const userApi = useUserApi();
    const formApi = useFormApi();
    const workflowApi = useWorkFlowApi();

    const [startTime, setStartTime] = useState<any>(new Date())
    const [working, setWorking] = useState<boolean>(false);

    const [config, setConfig] = useState<any>(null)
    //const [workflows, setWorkflows] = useState<any[]>([])
    const [modullies, setModullies] = useState<any[]>([])
    const [forms, setForms] = useState<any[]>([])

    const [solutionID, setSolutionID] = useState<any>(0);
    const user: any = useUser();
    const workflowSteps = useMasterWorkflows();
    const masterApi = useMasterApi();
    const dispatch: any = useDispatch()
    const [selectedImageUrls, setSelectedImageUrls] = useState<any>({})
    const [edit, setEdit] = useState<boolean>(false)
    const [multiFileList, setMultiFileList] = useState<any>({})
    const controlTypes: any = useControlTypes();
    const [uploadProgress,setUploadProgress] = useState<number>(0);

    const workflows = useWorkflows()

    const getSections = () => {
        masterApi.getSections({ urlParams: { companyID: user.companyID } }, (msg: any, resp: any) => {
            let workflowSteps: any = [];
            let sections: any = resp.data.workFlowSection
            masterApi.getPhases({ urlParams: { companyID: user.companyID } }, (msg: any, resp: any) => {
                let phases: any = resp.data.workFlowPhase
                sections.map((section: any) => {
                    let sec: any = {
                        sectionDetails: section,
                        phases: []
                    };
                    phases.map((phase: any) => {
                        if (phase.fkSectionValue == section.sectionID) {
                            sec.phases.push(phase)
                        }
                    })
                    workflowSteps.push(sec)
                })
                dispatch({
                    type: ACTIONS.MASTER.WORKFLOWS, payload: {
                        workflows: workflowSteps
                    }
                })
            }, (msg: any) => { })
        }, (msg: any) => { })
    }

    const getWorkFlows = () => {
        formApi.call(SAGA_ACTIONS.FORM.GET_WORKFLOWS, { urlParams: { companyID: user.companyID } }, (message: any, resp: any) => { }, (message: any) => { })
    }

    const getModules = (workFlowID: any) => {
        formApi.call(SAGA_ACTIONS.FORM.GET_MODULES, { urlParams: { WorkFowID: workFlowID, companyID: user.companyID } }, (message: any, resp: any) => {
            setModullies(resp.data.moduleIs.map((mod: any) => {
                mod.value = mod.moduleID;
                mod.label = mod.pageTitle;
                if (mod.pageTitle == _module) {
                    getForms(mod.moduleID)
                }
                return mod
            }))
        }, (message: any, resp: any) => {

        })
    }

    const getForms = (moduleID: any) => {
        formApi.call(SAGA_ACTIONS.FORM.GET_FORMS, { urlParams: { ModuleID: moduleID, companyID: user.companyID } }, (message: any, resp: any) => {
            /* setForms(resp.data.forms.map((form:any)=>{
                form.value = form.formID;
                form.label = form.title;
                return form
            })) */
            getFormConfig(resp.data.formID)
            setForms([{ ...resp.data, value: resp.data.formID, label: resp.data.title }])
        }, (message: any, resp: any) => {

        })
    }

    const getFormConfig = (formID: any) => {

        userApi.getFormConfig({ urlParams: { id: formID } }, (message: any, resp: any) => {
            setConfig(resp.data);
        }, (message: any) => {

        })
    }

    const getColClass = (field: any) => {
        let s: number = Math.round(12 / parseInt(config?.layout?.replace('Col:', '')))
        if (field.colSpan) {
            s = s * field.colSpan
        }
        if (s > 12) {
            s = 12
        }
        return 'col-md-' + s
    }

    const uploadFile = (files: any, local: boolean) => {
        return new Promise((resolve, reject) => {
            convertBase64(files[0]).then((base64File) => {
                if (local) {
                    resolve(base64File)
                } else {
                    formApi.uploadFile({
                        file: base64File, progress: (progressEvent:any) =>{
                            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            //console.log('percentCompleted =>', percentCompleted);
                            setUploadProgress(percentCompleted)
                        }
                    }, (message: any, resp: any) => {
                        resolve(resp.data.fileURL);
                        setUploadProgress(0)
                    }, (message: any) => {
                        reject(message)
                    })
                }

            })
        })
    }


    const onChangeImg = (files?: any, local: boolean = false) => {
        return new Promise((resolve: any, reject: any) => {
            if (files.length > 0) {
                uploadFile(files, local).then((url: any) => {

                    let type = 'file';
                    if (files[0].type.search('image/') == 0) {
                        type = 'image'
                    }

                    if (files[0].type.search('/pdf') > 0) {
                        type = 'pdf'
                    }

                    if (files[0].type.search('/zip') > 0) {
                        type = 'zip'
                    }
                    if (files[0].type.search('powerpoint') > 0) {
                        type = 'ppt'
                    }
                    if (files[0].type.search('.presentation') > 0) {
                        type = 'ppt'
                    }

                    if (files[0].type.search('.document') > 0) {
                        type = 'doc'
                    }
                    if (files[0].type.search('.word') > 0) {
                        type = 'doc'
                    }

                    resolve({ type: type, src: url, name: files[0].name })

                })
            } else {
                resolve(null)
            }
        })
    }

    const uploadMultiFileHandler = (field: any) => {
        toast.promise(
            uploadMultiFile(field), {
            loading: 'Uploading...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    const uploadMultiFile = (field: any) => {
        return new Promise((resolve: any, reject: any) => {
            let data: any = getValues();
            if (data[field.name + '_title'] && (document.getElementsByName(field.name)[0] as any).value) {
                uploadFile((document.getElementsByName(field.name)[0] as any).files, false).then((url: any) => {
                    if (multiFileList[field.name]) {
                        let newObj: any = multiFileList[field.name]
                        newObj.push({ text: data[field.name + "_title"], values: url })
                        setMultiFileList({ ...multiFileList, ...newObj })
                    } else {
                        let newObj: any = {}
                        newObj[field.name] = [{ text: data[field.name + "_title"], values: url }]
                        setMultiFileList({ ...multiFileList, ...newObj })
                    }
                    setValue(field.name + '_title', '');
                    setValue(field.name, '');
                    let newImages: any = selectedImageUrls;
                    delete newImages[field.name];
                    setSelectedImageUrls(newImages);

                    (document.getElementsByName(field.name)[0] as any).value = ''
                    resolve('Upload Complete')

                })
            } else {
                reject('file name and file required')
            }
        })

    }

    const removeFileFromList = (name:string, index:number) =>{
        let newValue:any = multiFileList[name];
        newValue.splice(index, 1)
        setMultiFileList({...multiFileList, [name]:newValue})
    }


    const getFileElement = (field: any, i: any) => {
        if (field.multiSelect) {
            return (
                <div className={getColClass(field)} key={i}>
                    <label htmlFor="" className="form-label">{field.caption}</label>

                    <div className="dynamic-file-upload form-control">
                        <Controller
                            defaultValue=""
                            control={control}
                            name={field.name + '_title'}
                            rules={{
                                required: false,
                            }}
                            render={({ field: { onChange, value, ref } }) => (
                                <input type="text" className="file-name" placeholder={''} ref={ref} onChange={onChange} value={value} />
                            )}
                        />
                        <div className="file-btn">

                            {selectedImageUrls[field.name] ? (
                                <>
                                    {selectedImageUrls[field.name].type == 'image' &&
                                        <img src={selectedImageUrls[field.name].src} style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'contain' }} />
                                    }


                                    {selectedImageUrls[field.name].type == 'pdf' &&
                                        <div className="text-center">
                                            <FaRegFilePdf style={{ fontSize: '24px' }} />
                                        </div>
                                    }


                                    {selectedImageUrls[field.name].type == 'zip' &&
                                        <div className="text-center">
                                            <BsFileEarmarkZip style={{ fontSize: '24px' }} />
                                        </div>
                                    }

                                    {selectedImageUrls[field.name].type == 'doc' &&
                                        <div className="text-center">
                                            <IoDocumentTextOutline style={{ fontSize: '24px' }} />
                                        </div>
                                    }

                                    {selectedImageUrls[field.name].type == 'ppt' &&
                                        <div className="text-center">
                                            <BsFileEarmarkPpt style={{ fontSize: '24px' }} />
                                        </div>

                                    }

                                    {selectedImageUrls[field.name].type == 'file' &&
                                        <div className="text-center">
                                            <FaRegFile style={{ fontSize: '24px' }} />
                                        </div>

                                    }

                                </>
                            )
                                :
                                <Icon name="file-upload" />
                            }
                            <Controller
                                control={control}
                                name={field.name}
                                rules={{
                                    required: false,
                                }}
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <input type="file" className="file" accept="image/*,.pdf,.zip,.doc,.docx,.ppt,.pptx" name={name} ref={ref} onChange={(e) => {
                                        onChangeImg(e.target.files, true).then((res: any) => {
                                            let set: any = {}
                                            set[name] = res
                                            setSelectedImageUrls({ ...selectedImageUrls, ...set });

                                            //let values:any = getValues();
                                            //if(values[field.name + '_title'] == ''){
                                            setValue(field.name + '_title', res.name.split('.')[0])
                                            //}
                                        });
                                        onChange(e)
                                    }} />
                                )}
                            />
                        </div>
                        <div className="bg-success bottom-0 m-0 position-absolute rounded-4" style={{left:'-4px', height: '4px', width:uploadProgress+'%' }}></div>
                    </div>

                    <div className="d-flex gap-3 justify-content-end mt-1">
                        <Button size="sm" onClick={() => { uploadMultiFileHandler(field) }}>Upload</Button>
                    </div>

                    {multiFileList[field.name] && multiFileList[field.name].length > 0 && (
                        <React.Fragment>
                            <hr />

                            <div className="table-responsive list-table">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="w-5">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">file</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {multiFileList[field.name] && multiFileList[field.name].length > 0 && (
                                            multiFileList[field.name].map((item: any, index: number) => (
                                                <tr key={field.name + '_' + index}>
                                                    <td><h6>{index + 1}</h6></td>
                                                    <td><h6>{item.text}</h6></td>
                                                    <td>
                                                        <div className="d-flex gap-3 justify-content-start" >
                                                            <FilePreview url={item.values} size={42} />
                                                        </div>
                                                    </td>


                                                    <td>
                                                        <div className="d-flex gap-3 justify-content-end">
                                                            <Icon name="delete" onClick={()=>{removeFileFromList(field.name, index)}} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>



                            </div>
                        </React.Fragment>
                    )}




                </div>
            )
        } else {

            return (
                <div className={getColClass(field)} key={i}>
                    <label htmlFor="" className="form-label">{field.caption}</label>
                    <div className="custom-file-uploader mb-4">

                        <Controller
                            defaultValue=""
                            control={control}
                            name={field.name}
                            rules={{
                                required: field.required ? field.required : false,
                            }}
                            render={({ field: { onChange, value, ref, name } }) => (
                                <input type="file" accept="image/*,.pdf,.zip,.doc,.docx,.ppt,.pptx" name={name} ref={ref} onChange={(e) => {
                                    onChangeImg(e.target.files).then((res: any) => {
                                        let set: any = {}
                                        set[name] = res
                                        setSelectedImageUrls({ ...selectedImageUrls, ...set })
                                    }); onChange(e)
                                }} />
                            )}
                        />
                        {selectedImageUrls[field.name] ? (
                            <>
                                {selectedImageUrls[field.name].type == 'image' &&
                                    <img src={selectedImageUrls[field.name].src} style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'contain' }} />
                                }


                                {selectedImageUrls[field.name].type == 'pdf' &&
                                    <div className="text-center">
                                        <FaRegFilePdf style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>
                                }


                                {selectedImageUrls[field.name].type == 'zip' &&
                                    <div className="text-center">
                                        <BsFileEarmarkZip style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>
                                }

                                {selectedImageUrls[field.name].type == 'doc' &&
                                    <div className="text-center">
                                        <IoDocumentTextOutline style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>
                                }

                                {selectedImageUrls[field.name].type == 'ppt' &&
                                    <div className="text-center">
                                        <BsFileEarmarkPpt style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>

                                }

                                {selectedImageUrls[field.name].type == 'file' &&
                                    <div className="text-center">
                                        <FaRegFile style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>

                                }

                            </>
                        )
                            :
                            <>
                                <i className="file-upload-icon"></i>
                                <p className="mb-0">Click to upload.</p>
                            </>
                        }

                        <div className="bg-success bottom-0 m-1 position-absolute rounded-4" style={{left:'-4px', height: '4px', width:uploadProgress+'%' }}></div>

                    </div>
                    <Error error={errors[field.name]} />
                </div>
            )

        }



    }

    const getTextElement = (field: any, i: any) => {
        if (!edit && defaultData[field.caption]) {
            setValue(field.name, defaultData[field.caption]);
        }
        return (
            <div className={getColClass(field)} key={i}>
                <label htmlFor="" className="form-label">{field.caption}</label>
                <Controller
                    defaultValue=""
                    control={control}
                    name={field.name}
                    rules={{
                        required: field.required ? field.required : false,
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                        <input type="text" className="form-control" placeholder={field.placeHolder} ref={ref} onChange={onChange} value={value} />
                    )}
                />

                <Error error={errors[field.name]} />

            </div>
        )
    }

    const getDatePickerElement = (field: any, i: any) => {
        if (!edit && defaultData[field.caption]) {
            setValue(field.name, defaultData[field.caption]);
        }
        return (
            <div className={getColClass(field)} key={i}>
                <label htmlFor="" className="form-label">{field.caption}</label>
                <Controller
                    defaultValue=""
                    control={control}
                    name={field.name}
                    rules={{
                        required: field.required ? field.required : false,
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                        <input type="date" className="form-control" placeholder={field.placeHolder} ref={ref} onChange={onChange} value={value} />
                    )}
                />

                <Error error={errors[field.name]} />

            </div>
        )
    }

    const getTextareaElement = (field: any, i: any) => {
        return (
            <div className={getColClass(field)} key={i}>
                <label htmlFor="" className="form-label">{field.caption}</label>
                <Controller
                    defaultValue=""
                    control={control}
                    name={field.name}
                    rules={{
                        required: field.required ? field.required : false,
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                        <textarea value={value} className="form-control" rows={5} placeholder={field.placeHolder} ref={ref} onChange={onChange}></textarea>
                    )}
                />

                <Error error={errors[field.name]} />


            </div>
        )
    }

    const getCheckboxElement = (field: any, i: any) => {
        return (
            <div className={getColClass(field)} key={i}>
                <label htmlFor="" className="form-label">{field.caption}</label>
                <div className="d-flex gap-3">
                    {Object.keys(field.options).map((valueKey: any, k: any) => (
                        <React.Fragment key={k}>
                            <div className="form-check">
                                <Controller
                                    defaultValue=""
                                    control={control}
                                    name={field.name + valueKey}
                                    rules={{
                                        required: false //field.required ? field.required : false,
                                    }}
                                    render={({ field: { onChange, value, ref, name } }) => (
                                        <input className="form-check-input" type="checkbox" name={name} value={valueKey} checked={value} ref={ref} onChange={(e) => {
                                            onChange(e)
                                        }} />
                                    )}
                                />


                                <label className="form-check-label" >
                                    {field.options[valueKey]}
                                </label>
                            </div>
                            {/* <Error error={errors[field.name + valueKey]} /> */}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        )
    }

    const getRadioElement = (field: any, i: any) => {
        return (
            <div className={getColClass(field)} key={i}>
                <label htmlFor="" className="form-label">{field.caption}</label>
                <div className="d-flex gap-3">
                    {Object.keys(field.options).map((valueKey: any, k: any) => (
                        <div className="form-check" key={k}>
                            <Controller
                                defaultValue=""
                                control={control}
                                name={field.name}
                                rules={{
                                    required: field.required ? field.required : false,
                                }}
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <input className="form-check-input" type="radio" name={name} value={valueKey} checked={valueKey == value} onChange={onChange} />
                                )}
                            />

                            <label className="form-check-label" >
                                {field.options[valueKey]}
                            </label>
                        </div>
                    ))}
                </div>

                <Error error={errors[field.name]} />
            </div>
        )
    }

    const getSelectElement = (field: any, i: any) => {
        let options: any = [];

        Object.keys(field.options).map((valueKey: any, k: any) => (
            options.push({ label: field.options[valueKey], value: valueKey })
        ))

        return (
            <div className={getColClass(field)} key={i}>
                <label htmlFor="" className="form-label">{field.caption}</label>
                <Controller
                    defaultValue=""
                    control={control}
                    name={field.name}
                    rules={{
                        required: field.required ? field.required : false,
                    }}
                    render={({ field: { onChange, value, ref, name } }) => (
                        <Select
                            name={name}
                            options={options}
                            onChange={onChange}
                            value={value}
                        />

                    )}
                />
            </div>
        )
    }

    const getLabelElement = (field: any, i: any) => {
        return (
            <div className={getColClass(field)} key={i}>
                <h3 className="h6 text-purple font-weight-600 mb-4">{field.caption}</h3>
            </div>
        )
    }

    const getHeadLineElement = (field: any, i: any) => {
        return (
            <div className={getColClass(field)} key={i}>
                <h2 className="h5">{field.caption}</h2>
                <hr />
            </div>
        )
    }

    const getComponent = (field: any, i: any) => {
        switch (field.text) {
            case 'productList':
                return <ProductList key={`comp-${i}`} useCaseID={useCaseID} heading={field.caption} solutionID={solutionID} />
            default:
                return null
        }
    }

    const submitData = (button: any) => {
        return new Promise((resolve, reject) => {
            setWorking(true)
            let data = getValues();

            let meta: any = [];

            Object.keys(metadata).map((key: any) => {
                meta.push({ InputKey: key, InputValue: metadata[key] })
            });

            let formData: any = {
                api: button.actionURL,
                "formId": config.formID,
                "fkCompanyID": user.companyID,
                "fkTenantID": user.tenantID,
                "metadata": meta,
                "status": 1,
                "userID": user?.userID,
                "timeSpendInSec": Math.round(((new Date()).getTime() - startTime.getTime()) / 1000),
                "answers": []
            }
            if (useCaseID) {
                formData.fkUseCaseID = useCaseID
            }

            let fileError: any = null;

            config.controls.map((field: any) => {
                switch ((getSelectedValue(field.fkControlTypeID, 'controlTypeID', controlTypes) as any)?.controlName) {
                    case 'FileField':
                        if (field.multiSelect) {
                            if (multiFileList[field.name] && multiFileList[field.name].length > 0) {
                                formData.answers.push({
                                    "fkFormControlID": field.fkFormControlID,
                                    "inputType": "File",
                                    "fkControlTypeID": field.fkControlTypeID,
                                    "values": multiFileList[field.name],
                                })
                            } else {
                                if (field.required) {
                                    fileError = field.caption + ' is required';
                                }
                            }
                        } else {
                            if (selectedImageUrls[field.name]) {
                                formData.answers.push({
                                    "fkFormControlID": field.fkFormControlID,
                                    "inputType": "File",
                                    "fkControlTypeID": field.fkControlTypeID,
                                    "values": [{ text: '', values: selectedImageUrls[field.name].src }],
                                })
                            }
                        }


                        break;
                    case 'TextBox':
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "TextBox",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [{ text: '', values: data[field.name] }]
                        })
                        break;
                    case 'TextArea':
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "TextArea",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [{ text: '', values: data[field.name] }]
                        })
                        break;
                    case 'CheckBox':
                        let val: any = []
                        Object.keys(field.options).map((valueKey: any, k: any) => {
                            if (data[field.name + valueKey]) {
                                val.push({ text: '', values: valueKey })
                            }

                        })

                        if (val.length > 0) {
                            formData.answers.push({
                                "fkFormControlID": field.fkFormControlID,
                                "inputType": "CheckBox",
                                "fkControlTypeID": field.fkControlTypeID,
                                "values": val
                            })
                        }

                        
                        break;
                    case 'Radio':
                        if (data[field.name]) {
                            formData.answers.push({
                                "fkFormControlID": field.fkFormControlID,
                                "inputType": "Radio",
                                "fkControlTypeID": field.fkControlTypeID,
                                "values": [{ text: '', values: data[field.name] }]
                            })
                        }
                        

                        break;
                    case 'Select':
                    case 'Dropdown':
                        if (data[field.name]) {
                            formData.answers.push({
                                "fkFormControlID": field.fkFormControlID,
                                "inputType": "Select",
                                "fkControlTypeID": field.fkControlTypeID,
                                "values": [{ text: '', values: data[field.name].value }]
                            })
                        }

                        break;
                    case 'DatePicker':
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "DatePicker",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [{ text: '', values: showDate(data[field.name], 'YYYY-MM-DD') }]
                        })

                        break;

                    default:
                        return null;
                }
            })


            if (fileError) {
                setWorking(false);
                reject(fileError)
                return;
            }

            console.log('before call...',formData)

     

            setTimeout(() => {
                userApi.submitDynamicForm(formData, (message: any, resp: any) => {
                    if (sded) {
                        // after success we have to post startDate endDate data..
                        workflowApi.updateStartDateEndDate({
                            useCaseID: useCaseID,
                            fkCompanyID: user.companyID,
                            phaseName: phase,
                            startDate: showDate(data.startDate, 'YYYY-MM-DD'),
                            completeDate: showDate(data.endDate, 'YYYY-MM-DD'),
                            updatedBy: user.userID
                        }, (message: any, resp: any) => {
                            resolve(message)
                            reset();
                            setSelectedImageUrls({});
                            setWorking(false)
                        }, (message: any) => {
                            resolve(message)
                            reset();
                            setSelectedImageUrls({});
                            setWorking(false)
                        })
                    } else {
                        resolve(message)
                        reset();
                        setSelectedImageUrls({});
                        setWorking(false)
                    }                    
                }, (message: any) => {
                    reject(message)
                    setWorking(false)
                })
            }, 1000)
        })
    }

    const submitButton = (button: any) => {
        if (isValid) {
            toast.promise(
                submitData(button), {
                loading: 'Saving...',
                success: (msg: any) => {
                    if (onSubmitSuccess) {
                        if(button.actionURL.search('Draft') < 0){
                            onSubmitSuccess(edit);
                        }
                    }
                    if (config && editMode && workflowSteps && ((section && phase) || answerID)) {
                        getDynamicFormData()
                    } else {
                        reset();
                    }
                    return <b>{msg}</b>
                },
                error: (msg: any) => { return <b>{msg}</b> },
            });
        } else {
            // ------
        }
    }

    const onSubmit = (data: any) => {
        // fake ..
    }

    const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };



    const getSolutionID = () => {
        return new Promise((resolve, reject) => {
            formApi.getSolutionID({ urlParams: { UseCaseID: useCaseID } }, (msg: any, resp: any) => {
                setSolutionID(resp.data.solutionFramingID);
                resolve(true);
            }, (msg: any) => { })
        })
    }

    const getDynamicFormData = () => {
        let sectionID = 0;
        let phaseID = 0;
        workflowSteps.map((_section: any) => {
            if (_section.sectionDetails.title == section) {
                sectionID = _section.sectionDetails.sectionID;
                _section.phases.map((_phase: any) => {
                    if (_phase.title == phase) {
                        phaseID = _phase.phaseValue
                    }
                })
            }
        })
        let params: any = {
            "companyID": user.companyID,
            "formID": forms[0].value,
            "fkUsecaseID": useCaseID,
        }
        if (answerID) {
            params.fkAnswerID = answerID
        } else {
            params.fkSectionID = sectionID
            params.fkPhaseID = phaseID
        }

        formApi.getDynamicFormData(params, (msg: any, resp: any) => {
            let answers: any = resp.data.answers;
            let ansOptions: any = resp.data.ansOptions;
            if (answers.length > 0) {
                setEdit(true)
            } else {
                setEdit(false)
            }
            let values: any = {};
            let localMultiFileList: any = {};
            answers.map((answer: any) => {
                config.controls.map((control: any) => {
                    if (answer.fkFormControlID == control.fkFormControlID) {
                        if (control.inputType == "FileField") {
                            if (answer.url) {
                                let ext = answer.url.split(/[#?]/)[0].split('.').pop().trim();
                                let type = 'file';
                                switch (ext.toLowerCase()) {
                                    case 'jpg':
                                    case 'jpeg':
                                    case 'png':
                                    case 'svg':
                                    case 'webp':
                                    case 'gif':
                                    case 'avif':
                                        type = 'image'; break;
                                    case 'pdf':
                                        type = 'pdf'; break;
                                    case 'zip':
                                    case 'x-zip-compressed':
                                        type = 'zip'; break;
                                    case 'doc':
                                    case 'docx':
                                        type = 'doc'; break;
                                    case 'ppt':
                                    case 'pptx':
                                        type = 'ppt'; break;



                                    default: type = 'file'; break;
                                }

                                if(control.multiSelect){
                                    let fileData:any = localMultiFileList[control.name]?localMultiFileList[control.name]:[]
                                    fileData.push({ text: answer.textAnswer, values: answer.url });
                                    localMultiFileList = {...localMultiFileList,[control.name]:fileData}
                                } else {
                                    let set: any = {}
                                    set[control.name] = { src: answer.url, type: type }
                                    setSelectedImageUrls({ ...selectedImageUrls, ...set })
                                    values[control.name] = answer.url
                                }
                            }
                        } else if (control.inputType == "CheckBox") {
                            let options: any = ansOptions.filter((opt: any) => { return opt.fkFormControlID == control.fkFormControlID })
                            Object.keys(control.options).map((key: any) => {
                                let checked = options.filter((opt: any) => { return opt.optionValue == control.options[key] })
                                values[control.name + key] = !!checked.length
                            })

                        } else if (control.inputType == "Select") {
                            let options: any = ansOptions.filter((opt: any) => { return opt.fkFormControlID == control.fkFormControlID })
                            values[control.name] = { label: options[0].optionValue, value: answer.textAnswer }
                        } else {
                            values[control.name] = answer.textAnswer || ''
                        }
                    }
                })
            })
            setMultiFileList(localMultiFileList);
            console.log('reset values ....=>',values)
            if(sded){
                // get start date end date value via get api 
                // set those values in values object... 
                // after that reset the react hook form for rendering previous values
                workflowApi.getStartDateEndDate({urlParams:{
                    companyID:user.companyID,
                    usecaseID:useCaseID,
                    phaseName:phase
                }}, (message: any, resp: any) => {
                    reset({...values,...{
                        startDate:resp.data.startDate,
                        endDate:resp.data.completeDate
                    }})

                    console.log('reset values =>',values)
                }, (message: any) => {
                    reset(values)
                })
            } else {
                reset(values)
            }

        
        }, (msg: any) => { })
    }

    const getControlTypes = () => {
        formApi.call(SAGA_ACTIONS.FORM.GET_CONTROL_TYPES, { urlParams: { PageIndex: 0, PageSize: 999 } }, (message: any, resp: any) => {

        }, (message: any, resp: any) => {

        })
    }
    const isDraftButton = () => {
        let status:boolean = false;
        config?.buttons.map((btn:any)=>{
            if(btn.actionURL.search('Draft') >=0){
                status = true
            }
        })
        return status
    }

    useEffect(() => {
        if (_form == 'solution-provider') {
            getSolutionID().then((status) => {
                setStartTime(new Date())
            })
        } else {
            setStartTime(new Date())
        }
        return () => {
            reset()
        }
    }, [])


    useEffect(() => {
        if (_startTime) {
            setStartTime(_startTime)
        }
    }, [_startTime])

    useEffect(() => {
        if (workflowSteps == null && user) {
            getSections();
        }
    }, [workflowSteps, user])

    useEffect(() => {
        if (workflows == null && user) {
            getWorkFlows();
        }
    }, [user, workflows])

    useEffect(() => {
        if (workflows && controlTypes) {
            workflows.map((flow: any) => {
                if (flow.title == _workflow) {
                    getModules(flow.workFlowID)
                }
            })
        }
    }, [workflows, controlTypes])


    useEffect(() => {
        if (config && editMode && workflowSteps && ((section && phase) || answerID)) {
            getDynamicFormData()
        } else {
            //----
        }
    }, [config, editMode, workflowSteps, section, phase, answerID])

    useEffect(() => {
        if (!controlTypes) {
            getControlTypes()
        }
    }, [controlTypes])

    return (
        <React.Fragment>
            <div className="form-area">
                {config ?
                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)} >
                        {config?.controls.map((field: any, i: any) => {
                            switch ((getSelectedValue(field.fkControlTypeID, 'controlTypeID', controlTypes) as any)?.controlName) {
                                case 'FileField': return getFileElement(field, i); break;
                                case 'TextBox': return getTextElement(field, i); break;
                                case 'TextArea': return getTextareaElement(field, i); break;
                                case 'CheckBox': return getCheckboxElement(field, i); break;
                                case 'Radio': return getRadioElement(field, i); break;
                                case 'Lebel': return getLabelElement(field, i); break;
                                case 'HeadLine': return getHeadLineElement(field, i); break;
                                case 'CustomControl': return getComponent(field, i); break;
                                case 'Select': 
                                case 'Dropdown': 
                                return getSelectElement(field, i); break;
                                case 'DatePicker': return getDatePickerElement(field, i); break;

                                default:
                                    return null;
                            }
                        })}



                        <div className="bg-white col-md-12 py-3 sticky-bottom">
                            <div className="row justify-content-between">
                                <div className="col-8">
                                    <div className="row">
                                        {sded ? <React.Fragment>
                                            <div className={'col-6'} >
                                                <label htmlFor="" className="form-label">Start Date</label>
                                                <Controller
                                                    defaultValue=""
                                                    control={control}
                                                    name={'startDate'}
                                                    rules={{ required: 'Start date is required' }}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <input type="date" className="form-control" placeholder='yyyy-mm-dd' ref={ref} onChange={onChange} value={value} />
                                                    )}
                                                />
                                                <Error error={errors['startDate']} />
                                            </div>
                                            <div className={'col-6'} >
                                                <label htmlFor="" className="form-label">End Date</label>
                                                <Controller
                                                    defaultValue=""
                                                    control={control}
                                                    name={'endDate'}
                                                    rules={{ required: 'End date is required' }}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <input type="date" className="form-control" placeholder='yyyy-mm-dd' ref={ref} onChange={onChange} value={value} />
                                                    )}
                                                />
                                                <Error error={errors['startDate']} />
                                            </div>
                                        </React.Fragment> : null}
                                    </div>
                                </div>
                                <div className="col-4 ">
                                    <p>&nbsp;</p>
                                    {/* {isDraftButton() &&
                                        <>
                                            {config?.status == 0 ? <p className="text-end text-danger">Not Publish</p> : <p className="text-end text-success">Published</p>}
                                        </>
                                    } */}
                                    
                                    <div className="d-flex justify-content-end gap-3 align-items-end">
                                        {config?.buttons.map((button: any, i: any) => (
                                            <div>
                                                <button key={`form-btn${i}`} disabled={working} type="submit" className={`btn btn-${button.theme ? button.theme : 'primary'} d-flex align-items-center gap-2`} onClick={() => { submitButton(button) }}>{button.caption} <i className="arrow-right"></i></button>
                                            </div>

                                        ))}
                                    </div>
                                    
                                </div>

                            </div>
                        </div>



                    </form>

                    : <Loader />}

            </div>
        </React.Fragment>



    )

}