import React, { useEffect, useState } from 'react'
import Layout from '../../../components/workflow/Layout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PageMenuRunway } from '../../../components/workflow/PageMenuRunway'
import { Button } from 'react-bootstrap'
import DynamicForm from '../../../components/dynamicForm/dynamicForm'
import RightSlideModal from '../../../components/RightSlideModal'
import { URLS } from '../../../_config'
import { getNameOnly, getSelectedValue, mergeListData, replaceUrlParams } from '../../../_config/helpers'
import { GoToNextPhaseButton } from '../../../components/workflow/elements/GoToNextPhaseButton'
import AddTestPlanUserModal from '../../../modals/addTestPlanUser.modal'
import { useWorkFlowApi } from '../../../_common/hooks/api/WorkFlowApiHook'
import { GoTrash } from 'react-icons/go'
import Swal from 'sweetalert2'
import toast from 'react-hot-toast'
import { useUser } from '../../../_common/hooks/authHook'
import Select from "react-select"
import TableLoader from '../../../components/tableLoader'
import FilePreview from '../../../components/filePreview'
import TitleBar from '../../../components/titleBar/TitleBar'
import Pagination from '../../../components/pagination/Pagination'
import SearchField from '../../../components/searchSection/SearchField'
import GetStartDateEndDate from '../../../components/getStartDateEndDate'
import { useUsecase } from '../../../_common/hooks/usecaseHook'


export const TestPlan = () => {
    const [rightPannel, setRightPannel] = useState<boolean>(false)
    const { id } = useParams();
    const workFlowApi = useWorkFlowApi();
    const navigate = useNavigate();
    const user: any = useUser();
    const usecase: any = useUsecase();
    const [showAddPlanModal, setShowAddPlanModal] = useState<boolean>(false);

    const [rolesResponsibilitiesList, setRolesResponsibilitiesList] = useState<any>(null)
    const [testCriteriaList, setTestCriteriaList] = useState<any>(null)
    const [isAssignAllCriteria, setIsAssignAllCriteria] = useState<boolean | null>(null)

    const [searchText, setSearchText] = useState<any>('');
    const [searchText2, setSearchText2] = useState<any>('');

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [pageLimit, setPageLimit] = useState<number>(10)

    const [page2, setPage2] = useState<number>(1)
    const [total2, setTotal2] = useState<number>(0)
    const [pageLimit2, setPageLimit2] = useState<number>(10)
    const [dateSubmited, setDateSubmited] = useState<boolean>(false)
    const [triggerSDED, setTriggerSDED] = useState<boolean>(false);
    const [SDEDPromise, setSDEDPromise] = useState<any>(null);

    const checkSDED = () => {
        let p: any = new Promise((resolve, reject) => {
            if (dateSubmited) {
                resolve(true)
            } else {
                setSDEDPromise(() => { return resolve })
                setTriggerSDED(true);
            }
        })
        return p;
    }

    const getTestingRolesResponsibilitiesByUsecase = () => {
        let options: any = [{ label: 'Unassign', value: 0 }];
        workFlowApi.getTestingRolesResponsibilitiesByUsecase({
            urlParams: { CompanyID: user.companyID, UseCaseID: id },
            pageNo: page,
            pageSize: pageLimit,
            SearchText: searchText
        }, (message: any, resp: any) => {

            setTotal(resp.totalRecords)
            if (resp.data?.userList && resp.data?.userList.length > 0) {
                options = [{ label: 'Unassign', value: 0 }, ...resp.data?.userList?.map((tester: any) => {
                    tester.label = getNameOnly(tester.userFullName)
                    tester.value = tester.testingRolesResponsibilitiesID
                    return tester;
                })]
            }

            setRolesResponsibilitiesList(options);
            console.log(options)
        }, (message: any) => {
            setRolesResponsibilitiesList(options);
        })
    }

    const getTestCriteriaList = () => {
        workFlowApi.getTestCasesAcceptanceCriteriaByUsecase({
            urlParams: { companyID: user.companyID, UseCaseID: id },
            pageNo: page2,
            pageSize: pageLimit2,
            SearchText: searchText2
        }, (message: any, resp: any) => {
            let temArr = resp.data?.testCriteriaList;

            setTotal2(resp.totalRecords)
            setTestCriteriaList(mergeListData('testCasesAcceptanceCriteriaID', 'fileURL', temArr))
        }, (message: any) => {
            setTestCriteriaList([]);
        })
    }


    const checkAssignAllCriteria = (temArr: any) => {
        let allAssigned = true;
        temArr && temArr.length > 0 && temArr.map((itemC: any, i: any) => {
            if (itemC.assignUserID == 0) {
                allAssigned = false;
            }
        })
        if (temArr == null || temArr?.length == 0) {
            allAssigned = false;
        }
        setIsAssignAllCriteria(allAssigned)
    }

    useEffect(() => {
        getTestingRolesResponsibilitiesByUsecase();
    }, [id, page, searchText])

    useEffect(() => {
        getTestCriteriaList();
    }, [id, page2, searchText2])

    useEffect(() => {
        checkAssignAllCriteria(testCriteriaList);
    }, [testCriteriaList])

    useEffect(() => {
        if (dateSubmited) {
            if (SDEDPromise) {
                SDEDPromise();
            }
        }
    }, [dateSubmited])

    useEffect(() => {
        if (triggerSDED) {
            setTriggerSDED(false)
        }
    }, [triggerSDED])

    const deleteTestPlanData = (testPlanId: any) => {
        return new Promise((resolve, reject) => {
            workFlowApi.TestingRolesResponsibilitiesDelete({
                "testingRolesResponsibilitiesID": testPlanId,
                "fkCompanyID": user.companyID,
                "updatedBy": user.userID
            }, (msg: any, resp: any) => {
                setPage(1)
                getTestingRolesResponsibilitiesByUsecase()
                resolve(msg)
            }, (msg: any) => {
                setRolesResponsibilitiesList([]);
                resolve('Deleted successfully.')
            })
        })
    }
    const deleteTestPlan = (testPlanId: any) => {
        Swal.fire({
            title: `Confirm alert!`,
            text: `Are you sure you want to delete this test plan?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, Delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteTestPlanData(testPlanId), {
                    loading: 'Deleting...',
                    success: (msg: any) => {
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });

    }

    const deleteTestCriteriaData = (criteriaId: any) => {
        return new Promise((resolve, reject) => {

            workFlowApi.DeleteTestCasesAcceptanceCriteria({
                "testCasesAcceptanceCriteriaID": criteriaId,
                "fkCompanyID": user.companyID,
                "updatedBy": user.userID
            }, (msg: any, resp: any) => {
                setPage2(1)
                getTestCriteriaList()
                resolve(msg)
            }, (msg: any) => {
                setTestCriteriaList([]);
                resolve('Deleted successfully.')
            })
        })
    }
    const deleteTestCriteria = (criteriaId: any) => {
        Swal.fire({
            title: `Confirm alert!`,
            text: `Are you sure you want to delete this Test Cases And Acceptance Criteria?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, Delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteTestCriteriaData(criteriaId), {
                    loading: 'Deleting...',
                    success: (msg: any) => {
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });

    }

    const assignUnassignCall = (data: any) => {
        return new Promise((resolve: any, reject: any) => {
            workFlowApi.AssignTestCasesAcceptanceCriteriaUser(data, (msg: any, resp: any) => {
                resolve(msg)
            }, (msg: any) => {
                reject(msg)
            })
        })
    }

    const assignAcceptanceCriteriaData = (tester: any, itemCriteria: any) => {
        return new Promise((resolve, reject) => {
            if (itemCriteria.status == "Assigned" && tester.value != 0) {
                assignUnassignCall({
                    "testCasesAcceptanceCriteriaID": itemCriteria.testCasesAcceptanceCriteriaID,
                    "testingRolesResponsibilitiesID": itemCriteria.testingRolesResponsibilitiesID,
                    "isUnAssign": true,
                    "fkCompanyID": user.companyID,
                    "updatedBy": user.userID,
                }).then((msg: any) => {
                    assignUnassignCall({
                        "testCasesAcceptanceCriteriaID": itemCriteria.testCasesAcceptanceCriteriaID,
                        "testingRolesResponsibilitiesID": tester.value == 0 ? itemCriteria.testingRolesResponsibilitiesID : tester.testingRolesResponsibilitiesID,
                        "isUnAssign": tester.value == 0 ? true : false,
                        "fkCompanyID": user.companyID,
                        "updatedBy": user.userID,
                    }).then((msg: any) => {
                        getTestCriteriaList()
                        resolve(msg)
                    }).catch((msg) => {
                        setTestCriteriaList([]);
                        resolve('Deleted successfully.')
                    })
                }).catch((msg) => {
                    reject('Something Wrong!')
                })
            } else {
                assignUnassignCall({
                    "testCasesAcceptanceCriteriaID": itemCriteria.testCasesAcceptanceCriteriaID,
                    "testingRolesResponsibilitiesID": tester.value == 0 ? itemCriteria.testingRolesResponsibilitiesID : tester.testingRolesResponsibilitiesID,
                    "isUnAssign": tester.value == 0 ? true : false,
                    "fkCompanyID": user.companyID,
                    "updatedBy": user.userID,
                }).then((msg: any) => {
                    getTestCriteriaList()
                    resolve(msg)
                }).catch((msg) => {
                    setTestCriteriaList([]);
                    resolve('Deleted successfully.')
                })

            }

        })
    }

    const assignAcceptanceCriteria = (tester: any, itemCriteria: any) => {
        Swal.fire({
            title: `Confirm alert!`,
            text: `Are you sure you want to ${tester.value == 0 ? 'Unassign' : 'assign'} this user?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, ${tester.value == 0 ? 'Unassign' : 'assign'} it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    assignAcceptanceCriteriaData(tester, itemCriteria), {
                    loading: 'Assigning...',
                    success: (msg: any) => {
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }

    return (

        <Layout titleBar={
            <TitleBar title={`Test Plan | usecase : ${usecase?.useCase}`} breadcrumbs={{
                'Use case list': URLS.USE_CASE_LIST,
                'Use case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                'Test Plan': '#'
            }} >
                {/* <Timer startTime={new Date()} /> */}
            </TitleBar>
        }>

            <PageMenuRunway pageTab="TestPlan" />

            {
                showAddPlanModal && <AddTestPlanUserModal
                    show={showAddPlanModal}
                    onClose={setShowAddPlanModal}
                    receiveNewData={(data) => { getTestingRolesResponsibilitiesByUsecase() }} />
            }

            <div className="white-box">
                <div className="form-area mb-5">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="mb-0">Testing Roles and Responsibilities</h6>
                        <SearchField onSearch={(str: any) => {
                            setSearchText(str)
                            setPage(1)
                        }} />
                        <button onClick={() => { setShowAddPlanModal(true) }} className="btn btn-info py-2 show-btn-2" >Add Tester</button>
                    </div>
                    <hr />

                    <div className="table-responsive list-table">
                        <table className="table table-striped">
                            <thead>
                                <tr className="align-middle">
                                    <th scope="col" className="w-10 text-center">#</th>
                                    <th scope="col">Role</th>
                                    <th scope="col" className="w-50">Name</th>
                                    <th scope="col" className="w-10">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rolesResponsibilitiesList && rolesResponsibilitiesList.length > 0 &&
                                    rolesResponsibilitiesList.map((item: any, i: any) => {
                                        if (item.value == 0) {
                                            return null;
                                        } else {
                                            return (
                                                <tr className="align-middle h-60" key={`rolesResponsibilitiesList ${i}`}>

                                                    <td className='text-center'>{i}</td>
                                                    <td>{item?.roleName}</td>
                                                    <td>{getNameOnly(item?.userFullName)}</td>
                                                    <td>
                                                        <div className="d-flex gap-3">
                                                            <GoTrash className='react-icon-grey cursor-pointer' onClick={() => deleteTestPlan(item?.testingRolesResponsibilitiesID)} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }

                                    })
                                }

                            </tbody>
                        </table>
                        <TableLoader data={rolesResponsibilitiesList} />
                        <Pagination total={total} page={page} setPage={setPage} pageLimit={pageLimit} />

                    </div>

                </div>

                <div className="form-area">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="mb-0">Test Cases And Acceptance Criteria</h6>
                        <SearchField onSearch={(str: any) => {
                            setSearchText2(str)
                            setPage2(1)
                        }} />
                        <Button onClick={() => { setRightPannel(true) }} className="btn btn-info py-2 show-btn show-btn-2">Add Criteria</Button>
                    </div>
                    <hr />

                    <div className=" list-table resp-table" >
                        <table className="table table-striped">
                            <thead>
                                <tr className="align-middle">
                                    <th scope="col" className="w-10 text-center"> # </th>
                                    <th scope="col">Test Case </th>
                                    <th scope="col" >File</th>
                                    <th scope="col" >Created By</th>
                                    <th scope="col" className="w-25" >Assign To</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    testCriteriaList && testCriteriaList.length > 0 && testCriteriaList.map((itemCriteria: any, i: any) => (
                                        <tr className="align-middle h-60" key={`itemCriteria ${itemCriteria.testCasesAcceptanceCriteriaID}`}>
                                            <td className="text-center">{i + 1} </td>
                                            <td><h6>{itemCriteria?.testCasesAcceptanceCriteria}</h6>
                                                <p>{itemCriteria?.description}</p>
                                            </td>
                                            <td  >
                                                <FilePreview url={itemCriteria?.fileURL} size={32} />
                                            </td>
                                            <td>{itemCriteria?.addedByName}</td>
                                            <td >
                                                <Select
                                                    name="color"
                                                    options={rolesResponsibilitiesList}
                                                    onChange={(tester) => { assignAcceptanceCriteria(tester, itemCriteria) }}
                                                    defaultValue={getSelectedValue(itemCriteria.testingRolesResponsibilitiesID, 'value', rolesResponsibilitiesList)}
                                                />
                                            </td>
                                            <td>
                                                <div className="d-flex gap-3">
                                                    <GoTrash className='react-icon-grey cursor-pointer'
                                                        onClick={() => deleteTestCriteria(itemCriteria?.testCasesAcceptanceCriteriaID)} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>


                        <TableLoader data={testCriteriaList} />
                        <Pagination total={total2} page={page2} setPage={setPage2} pageLimit={pageLimit2} />

                    </div>


                </div>

                <hr />

                <div className="align-items-end bg-white pb-3 pt-2 rounded-1 row sticky-bottom">
                    <div className="col-md-8">
                        <GetStartDateEndDate submited={setDateSubmited} submitTrigger={triggerSDED} dataFor={{
                            useCaseID: id,
                            fkCompanyID: user.companyID,
                            phaseName: 'Test Plan',
                            startDate: '',
                            completeDate: '',
                            updatedBy: user.userID
                        }}
                        />
                    </div>
                    <div className="col-md-4">
                        <div className='d-flex justify-content-end gap-3'>
                            {
                                isAssignAllCriteria ?
                                    <GoToNextPhaseButton usecaseID={id} label="Submit" nextPhase='In-Field Testing' permission={checkSDED} onSuccess={() => {
                                        navigate(replaceUrlParams(URLS.WORKFLOW.INTHEAIR.FIELD_TESTING, { id: id }))
                                    }} />
                                    :
                                    <Button disabled>Submit</Button>
                            }
                        </div>
                    </div>
                </div>

            </div>


            <RightSlideModal visible={rightPannel} changeVisibility={setRightPannel}
                heading='Add Test Cases & Acceptance Criteria' timer={true}>
                <DynamicForm _workflow='runway' useCaseID={id} _module='test-plan--tester-case' _form='test-plan--tester-case' onSubmitSuccess={() => { setRightPannel(false); getTestCriteriaList() }} />
            </RightSlideModal>


        </Layout>


    )
}
