import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form"
import { Link, useNavigate } from 'react-router-dom';
import './Auth.scss'
import toast from "react-hot-toast"
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { URLS } from "../../_config";
import { isMobile } from 'react-device-detect';

import { SignOutButton, SignInButton, SignedIn, SignedOut, useUser, useAuth } from "@clerk/clerk-react"
import { Button } from "react-bootstrap";
import Icon from "../../components/icon";
import Loader from "../../components/loader";


const Login = () => {
    const navigate = useNavigate();
    const userApi = useUserApi()
    const queryParams = new URLSearchParams((window as any).location.search);
    const [showPassword, setShowPassword] = useState(false);

    const reUrl: any = queryParams.get('redirect');


    const ClerkUser = useUser();
    const ClerkAuth = useAuth();
    // console.log('ClerkUser---', ClerkUser)
    type Inputs = {
        username: string
        password: string
    }

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>()

    const submitData = (data: any) => {
        return new Promise((resolve, reject) => {
            userApi.login(data, (message: any, resp: any) => {
                resolve(message);
            }, (message: any) => {
                reject(message);
            })
        })
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        toast.promise(
            submitData(data), {
            loading: 'Logging...',
            success: (msg: any) => {
                if (reUrl) {
                    let re = new URL(reUrl)
                    navigate(re.pathname + re.search);
                } else {
                    // if(isMobile){
                    //     navigate(URLS.USE_CASE_LIST_GRID);
                    // } else {
                    navigate(URLS.USE_CASE_LIST);
                    // }

                }
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }


    return (
        <div className="login_page">

            <div className="loin_area">
                <div className="login_logo d-flex justify-content-center mb-2">
                    <Link to='#'><img src="https://ik.imagekit.io/innovationforce/InnovationForceSAAS/Logo/Logo-dark.svg?abc" width={220}/></Link>
                </div>
                <h3 className="h3">Welcome!</h3>
                <p>Sign in to your account.</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login_content">
                        <div className="loin_input">
                            <input type="text" {...register("username", { required: "Email Address is required" })} placeholder="Email Address" className="input_box" />
                            <span className="username-icon position-absolute right-0 top-0"></span>
                        </div>
                        {errors.username && <span className="text-danger">{errors.username.message}</span>}
                        <div className="loin_input">
                            <input type={showPassword ? 'text' : 'password'} {...register("password", { required: "Password is required" })} placeholder="Password" className="input_box" />
                            <span className="password-icon position-absolute right-0 top-0 cursor-pointer" onClick={() => setShowPassword(!showPassword)}></span>
                        </div>
                        {errors.password && <span className="text-danger">{errors.password.message}</span>}
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        {/* <div className="remember_txt">
                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">Remember Me</label>
                        </div> */}
                        <p><Link to={URLS.FORGOT_PASSWORD} className="text-purple text-sm"><i>Forgot password?</i></Link></p>
                    </div>

                    <div className="d-flex justify-content-center pt-2">
                        <Button type="submit" size="lg" className="d-flex">Sign in <Icon name='arrow-right' size={18} /></Button>
                    </div>
                    <div className="or_button_devider my-3">
                        <span>OR</span>
                    </div>
                    <div className="d-flex justify-content-center pt-2">
                        {
                            (!ClerkUser?.isLoaded) && <Loader />
                        }
                        <SignedOut>
                            <SignInButton redirectUrl={URLS.LOGIN_SSO}
                                children={<Button variant='secondary' size="lg" className="d-flex" >
                                    <Icon name="shield-lock-outline" size={18} />
                                    &nbsp; Login with SSO</Button>} />  &nbsp;&nbsp;
                        </SignedOut>
                        <SignedIn>
                            <SignOutButton children={
                            <Button variant='secondary' size="lg" className="d-flex" >
                                <Icon name="shield-lock-outline" size={18} />
                                &nbsp; Already SSO Loggedin | Logout
                            </Button>} /> &nbsp;&nbsp;
                        </SignedIn>
                    </div>
                </form>
                {/* <p className="dont_account mt-3">Don't have an account? <Link to="/signup">Sign Up</Link></p> */}
            </div>
        </div>
    );
}

export default Login;