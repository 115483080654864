import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../components/workflow/Layout'
import { PageMenu } from '../../../components/workflow/PageMenu'
import { Link, useNavigate, useParams } from 'react-router-dom'
import DynamicForm from '../../../components/dynamicForm/dynamicForm'
import { useUserApi } from '../../../_common/hooks/api/UserApiHook'
import { showDate } from '../../../_config/functions'
import { getNameOnly, replaceUrlParams } from '../../../_config/helpers'
import { URLS } from '../../../_config'
import { GoToNextPhaseButton } from '../../../components/workflow/elements/GoToNextPhaseButton'

import { BsDownload } from "react-icons/bs";
import TitleBar from '../../../components/titleBar/TitleBar'
import Timer from '../../../components/timer/Timer'
import { useUser } from '../../../_common/hooks/authHook'
import Pagination from '../../../components/pagination/Pagination'
import TableLoader from '../../../components/tableLoader'
import SearchField from '../../../components/searchSection/SearchField'
import GetStartDateEndDate from '../../../components/getStartDateEndDate'
import Icon from '../../../components/icon'
import { ConfirmAlert } from '../../../_common/confirmAlert'
import { useUsecase } from '../../../_common/hooks/usecaseHook'


export const Discovery = () => {
    const userApi = useUserApi();
    const user: any = useUser();
    const { id } = useParams();
    const usecase: any = useUsecase();
    const navigate = useNavigate();
    const [addMore, setAddMore] = useState<boolean>(false)
    const [allItems, setAllItems] = useState<any>(null);
    const [searchText, setSearchText] = useState<any>('');
    const [addStartTime, setAddStartTime] = useState<Date | null>(null)

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [pageLimit, setPageLimit] = useState<number>(10)
    const [dateSubmited, setDateSubmited] = useState<boolean>(false)
    const [triggerSDED, setTriggerSDED] = useState<boolean>(false);
    const [SDEDPromise, setSDEDPromise] = useState<any>(null);

    const [allowWorkflow, setAllowWorkflow] = useState<boolean>(false);

    const getAllDiscovery = (id: any) => {
        userApi.getAllDiscovery({
            urlParams: { CompanyID: user.companyID, UseCaseID: id },
            pageNo: page,
            pageSize: pageLimit,
            Title: searchText
        },
            (message: any, resp: any) => {
                setTotal(resp.totalRecords)
                setAllItems(resp.data.discoveryList.filter((file: any) => { return (file.discoveryID != 0) }))
            }, (message: any) => {
                setAllItems([])
            })
    }

    const checkSDED = ()=>{
        let p:any = new Promise((resolve,reject)=>{
            if(dateSubmited){
                resolve(true)
            } else {
                setSDEDPromise(()=>{ return resolve})
                setTriggerSDED(true);
            }
        })
        return p;
    }

    const deleteDiscoveryFile = (params:any) => {
        return new Promise((resolve:any,reject:any)=>{
            userApi.deleteDiscoveryFile(params, resolve, reject)
        })
    }

    useEffect(() => {
        getAllDiscovery(id)
    }, [id, page, searchText])


    useEffect(() => {
        if(dateSubmited){
            if(SDEDPromise){
                SDEDPromise();
            }
        }
    }, [dateSubmited])

    useEffect(() => {
        if(triggerSDED){
            setTriggerSDED(false)
        }
    }, [triggerSDED]);


    useEffect(()=>{
        if(usecase){
            if ( user.userID == usecase.fkCoCaptainID || user.userID == usecase.fkAitportManagerID || user.userID == usecase.fkCaptainID) {
                setAllowWorkflow(true)
            }
    
            if(user.roleKey == 'INNOVATION_MANAGER_ROLE' || user.roleKey == 'ADMIN' ){ //&& usecase?.assignedInnovationManagerID == user.userID
                setAllowWorkflow(true)
            }
            if(user.roleKey == 'INNOVATION_MANAGER_ROLE' && usecase?.assignedInnovationManagerID == 0){
                setAllowWorkflow(true)
            }
        }
        
    },[usecase])

    return (

        <Layout titleBar={
            <TitleBar title={`Discovery | usecase : ${usecase?.useCase}`} breadcrumbs={{
                'Use case list': URLS.USE_CASE_LIST,
                'Use case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                'Discovery': '#'
            }} >
                <Timer startTime={addStartTime} />
            </TitleBar>
        }>

            <PageMenu pageTab="Discovery" />
            <div className="white-box p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className="h5 mb-0 font-weight-600">Discovery</h1>
                    <SearchField onSearch={(str: any) => {
                        setSearchText(str)
                        setPage(1)
                    }} />
                    <Link to="#" onClick={() => { setAddMore(true); setAddStartTime(new Date()) }} className="btn btn-info py-2 add-more-files-btn">Add Discovery</Link>
                </div>
                <div className={"light-blue-bg files-add-panel mt-3 " + (addMore ? '' : 'd-none')}>
                    <div className="row form-area">
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-end">
                                <Link to="#" onClick={() => { setAddMore(false); setAddStartTime(null) }} className="close-btn"><i className="close-icon"></i></Link>
                            </div>
                        </div>

                        <div className="col-sm-12 pt-3">
                            <DynamicForm _workflow='hangar' useCaseID={id} _module='create-discovery' _form='discovery'
                                onSubmitSuccess={(edit) => { setAddMore(false); getAllDiscovery(id); setAddStartTime(null) }}
                                _startTime={addStartTime} />
                        </div>
                    </div>
                </div>

                <div className="table-responsive list-table py-3">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" className="w-5">#</th>
                                <th scope="col">Title</th>
                                <th scope="col">Note</th>
                                <th scope="col">Uploaded By</th>
                                <th scope="col">Uploaded at</th>
                                <th scope="col" className="w-150px">Operations</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allItems && allItems.length > 0 && (
                                allItems.map((item: any, index: number) => (
                                    <tr key={index}>
                                        <th>
                                            {index + 1}
                                        </th>
                                        <td>
                                            <h6>{item.discovery ? item.discovery : '--'}</h6>
                                        </td>
                                        <td>{item.note ? item.note : '--'}</td>
                                        <td>{item.userFullName ? getNameOnly(item.userFullName) : '--'}</td>

                                        <td>{showDate(item.addedDate)}</td>
                                        <td>
                                            <div className="d-flex gap-3 align-items-center">

                                                <a href={item.fileURL} download target='_blank' className="react-icon-grey"><Icon name='download' size={19} /></a>

                                                {allowWorkflow &&
                                                    <Icon name='delete' onClick={() => {
                                                        ConfirmAlert.delete('Discovery File', deleteDiscoveryFile, {
                                                            "discoveryID": item.discoveryID,
                                                            "fkUseCaseID": id,
                                                            "fkUserID": user.userID,
                                                            "fkCompanyID": user.companyID
                                                          }).then(() => {
                                                            getAllDiscovery(id);
                                                        })
                                                    }}
                                                    />
                                                }

                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                    <TableLoader data={allItems} />
                    <Pagination total={total} page={page} setPage={setPage} pageLimit={pageLimit} />
                </div>

                {/* {allItems && allItems.length > 0 && ( */}
                <div className="align-items-end bg-white pb-3 pt-2 rounded-1 row sticky-bottom">
                    <div className="col-md-8">
                        <GetStartDateEndDate submited={setDateSubmited} submitTrigger={triggerSDED} dataFor={{
                            useCaseID: id,
                            fkCompanyID: user.companyID,
                            phaseName: 'Discovery',
                            startDate: '',
                            completeDate: '',
                            updatedBy: user.userID
                        }}
                        />
                    </div>
                    <div className="col-md-4">
                        <div className='col-12 d-flex justify-content-end gap-3'>
                            <GoToNextPhaseButton usecaseID={id} nextPhase='Challenge Statement' permission={checkSDED} onSuccess={() => {
                                navigate(replaceUrlParams(URLS.WORKFLOW.HANGER.CHALLENGE, { id: id }))
                            }} />
                        </div>
                    </div>
                </div>
                {/* )} */}

            </div>
            
         

            
        </Layout>
    )
}
