import toast from "react-hot-toast";
import Swal from "sweetalert2";

const makeAlert = (type:string,api:any,params:any, name:string, resolve:any)=>{
	let title = '';
	let text = '';
	let icon:any = '';
	let btnText = '';
	let msg = '';
	switch(type){
		case 'delete':
			title = 'Confirm Delete';
			text = `Are you sure you want to delete this ${name}?`;
			icon = 'warning';
			btnText = `Yes, delete it!`;
			msg = 'Deleting...';
		break;
		case 'archive':
			title = 'Confirm Archive';
			text = `Are you sure you want to archive this ${name}?`;
			icon = 'warning';
			btnText = `Yes, Archive it!`;
			msg = 'Processing...';
		break;
		case 'invite':
			title = 'Confirm Invite';
			text = `Are you sure you want to send invitation to ${name}?`;
			icon = 'warning';
			btnText = `Yes, Send it!`;
			msg = 'Sending...';
		break;
	}

	Swal.fire({
		title: title,
		text: text,
		icon: icon,
		showCancelButton: true,
		confirmButtonColor: '#B32F84',
		cancelButtonColor: '#44546A',
		confirmButtonText: btnText
	}).then((result) => {
		if (result.isConfirmed) {
			toast.promise(
				api(params), {
				loading: msg,
				success: (msg: any) => {
					resolve(msg)
					return <b>{msg}</b>
				},
				error: (msg: any) => {
					return <b>{msg}</b>
				},
			});
		}
	});
}

export class ConfirmAlert {
	static delete(name: string, api: any, params: any) {
		return new Promise((resolve: any, reject: any) => {
			makeAlert('delete',api,params,name,resolve)
		})
	}

	static archive(name: string, api: any, params: any) {
		return new Promise((resolve: any, reject: any) => {
			makeAlert('archive',api,params,name,resolve)
		})
	}
	static invite(name: string, api: any, params: any) {
		return new Promise((resolve: any, reject: any) => {
			makeAlert('invite',api,params,name,resolve)
		})
	}

}
 