import React, { useEffect, useRef, useState } from "react";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link, useLocation, useNavigate } from "react-router-dom";
import './usecaseList.scss';
import { URLS } from "../../_config";
import { onReachedEnd, replaceUrlParams } from "../../_config/helpers";
import { showDate } from "../../_config/functions";
import TableLoader from "../tableLoader";
import { useUser } from "../../_common/hooks/authHook";
import { usecaseFilterData } from "../../_config/dataTypes";
import Masonry from "masonry-layout";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";



interface props {
    filter?:usecaseFilterData
}

export function UsecaseGrid({filter}:props) {
    const [useCaseList, setUseCaseList] = useState<any>(null);
    const [useCaseListPages, setUseCaseListPages] = useState<any>(null);

    const userApi = useUserApi();
    const user:any = useUser();
    const [pages, setPages] = useState<number>(0)
    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [pageLimit, setPageLimit] = useState<number>(12)
    const location = useLocation();
    const navigate = useNavigate()

    

    const getUseCaseList = () => {
        userApi.useCaseList({
            fkCompanyID:user.companyID,
            pageNo:page,
            pageSize:pageLimit, ...filter
        }, (message: any, resp: any) => {
            setTotal(resp.totalRecords)
            setPages(Math.ceil(resp.totalRecords/pageLimit));
            let data:any = {};
            data[`page${page}`] = resp.data.usecaseList.filter((usecase:any)=>{
                if(usecase.fkStatusID == 18 && usecase.addedBy != user.userID){
                    return false
                } else {
                    return true;
                }
            })
            if(useCaseListPages){
                setUseCaseListPages({...useCaseListPages,...data})
            } else {
                setUseCaseListPages(data)
            }
            
            //setUseCaseList(resp.data.usecaseList);
        }, (message: any, resp: any) => {
            setUseCaseList([])
        })

    }

    const masonryContainerRef = useRef<HTMLDivElement | null>(null);

    const initializeMasonry = () => {
        if (masonryContainerRef.current) {
            const containerElement = masonryContainerRef.current;
            let masonry: any = new Masonry(containerElement, {
                itemSelector: '.masonry-item',
                percentPosition: true,

            });
        }
    }


    const usecaseLike = (usecase:any) => {
        return new Promise((resolve, reject) => {
            userApi.usecaseLike({
                "fkUseCaseID": usecase.useCaseID,
                "fkUserID": user.userID,
                "fkCompanyID": user.companyID,
                "statusID": usecase.likeStatusID == 1 ? 2 : 1,
                "userFullName": user.userProfileDisplayName
            }, (message: any, resp: any) => {
                resolve(message)
            }, (message: any) => {
                reject(message)
            })
        })
    }
    const usecaseLikeHandler = (usecase:any) => {
        toast.promise(
            usecaseLike(usecase), {
            loading: 'Updating...',
            success: (msg: any) => {
                getUseCaseList();
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    useEffect(() => {
        getUseCaseList()
    }, [filter]);

    useEffect(() => {
        getUseCaseList()
    }, [page]);

    useEffect(() => {
        if (useCaseListPages) {
            let list: any = [];
            Object.keys(useCaseListPages).map((key) => {
                list = [...list, ...useCaseListPages[key]]
            })
            setUseCaseList(list)
            console.log(list)
        }

    }, [useCaseListPages]);

    useEffect(()=>{
        let qparams:any = new URLSearchParams(location.search);
        if(qparams.get('reload') == 'true'){
            getUseCaseList();
            navigate(URLS.USE_CASE_LIST)
        }
    },[location])
  



    return (
        <div className="pt-3">
        <div className="masonry-container">
            <div className="masonry-idea-list">

                <div className="row" ref={masonryContainerRef} >
                    {useCaseList && useCaseList.length > 0 && (
                        useCaseList.map((usecase: any) => (
                            <div className="col-sm-6 col-lg-3 mb-2 masonry-item" key={usecase.useCaseID}>
                                <div className="card">
                                    <Link to={replaceUrlParams(URLS.USECASE_DETAILS, { id: usecase.useCaseID })}>
                                        <img src={usecase.imgURL}
                                            alt=""
                                            loading="lazy"
                                            data-src={usecase.imgURL}
                                            onLoad={() => { initializeMasonry() }}
                                        />
                                    </Link>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h4 className="text-sm font-weight-600 mb-0">{usecase.useCase}</h4>
                                                <p className="text-sm text-grey">{usecase.addedByName}</p>
                                            </div>
                                            <div>
                                                <h6 className="text-xs text-grey">{showDate(usecase.addedDate)}</h6>
                                                <span className="tags tags-purple">{usecase.status}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex idea-stats-panel mt-2">
                                            <div className="flex-1 idea-stats-block">
                                                <h6 className="text-sm text-white pb-2">Score</h6>
                                                <h6>{usecase?.score}</h6>
                                            </div>
                                            <div className="flex-1 idea-stats-block">
                                                <img src="./images/rank.png" alt="#" />
                                                <h6>{usecase.rank ? usecase.rank : '0'}</h6>
                                            </div>
                                            <div className="flex-1 idea-stats-block">
                                                <img src="./images/vote.svg" alt="#" onClick={()=>{usecaseLikeHandler(usecase)}} className="cursor-pointer" />
                                                <h6>{usecase.likes ? usecase.likes : '0'}</h6>
                                            </div>
                                            <div className="idea-stats-section">
                                                <h5 className="text-sm text-white">Section</h5>
                                                <h6 className="text-sm text-white">Hanger</h6>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-3">
                                            <div className="flex-1 px-1">
                                                <h5 className="text-sm">Airport</h5>
                                                <p className="text-sm mb-0">
                                                    {usecase.airPort ? usecase.airPort : 'NA'}
                                                </p>
                                            </div>
                                            <div className="flex-1 px-1">
                                                <h5 className="text-sm">Captain</h5>
                                                <p className="text-sm mb-0">{usecase.captain ? usecase.captain : 'NA'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}

                    <TableLoader data={useCaseList} />
                    
                </div>
                <div className="d-flex justify-content-center">
                {pages > 0 && page < pages && (
                        <Button onClick={()=>{setPage(page+1)}}>View More</Button>
                    )}
                </div>


                {/* <Pagination /> */}
            </div>
        </div>
    </div>
    );
}
