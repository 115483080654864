import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { URLS } from "../../_config";
import { SlPeople } from "react-icons/sl"; 
import { TbReport } from "react-icons/tb";
import { TbBulb } from "react-icons/tb";
import { GrDocumentText } from "react-icons/gr";
import { GiAirplaneDeparture } from "react-icons/gi";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { MdLocalAirport } from "react-icons/md";
import { FaUserShield } from "react-icons/fa";
import Icon from "../icon";

const Footer = () => {
    return (
        <>
            <div className="mob-menu-wrapper print-hide">
                <Nav variant="pills" activeKey="1" className="mob-menu d-flex"> 
                    <div className="nav-item-mob dashboard-mobile-footer">
                        <MdOutlineDashboardCustomize />
                        <NavDropdown title="Dashboard" id="nav-dropdown">
                            <NavDropdown.Item as={Link} to={URLS.DASHBOARD_EXECUTIVE} className="align-items-center" ><MdOutlineDashboardCustomize style={{ fontSize: '18px' }} /> Executive Dashboard</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={URLS.DASHBOARD_PERIOD_COMPARISON} className="align-items-center" ><MdOutlineDashboardCustomize style={{ fontSize: '18px' }} /> Date Range Comparison Dashboard</NavDropdown.Item> 
                        </NavDropdown>
                    </div>

                    <Nav.Item className="nav-item-mob">
                        <Nav.Link as={Link} to={URLS.AIRPORT.LIST} className="d-flex align-items-center gap-2"><MdLocalAirport /> Airport</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item-mob">
                        <Nav.Link as={Link} to={URLS.RUNWAY.LIST} className="d-flex align-items-center gap-1"><GiAirplaneDeparture style={{ fontSize: '18px' }} /> Runway</Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="nav-item-mob">
                        <Nav.Link as={Link} to={URLS.USE_CASE_LIST} className="d-flex align-items-center gap-2"><GrDocumentText /> Use Case</Nav.Link>
                    </Nav.Item>

                    <div className="nav-item-mob dashboard-mobile-footer">
                        <i className="more-icon"></i>
                        <NavDropdown title="More" id="nav-dropdown">
                            <NavDropdown.Item as={Link} to={URLS.EMPLOYEE_LIST} className="align-items-center" ><SlPeople style={{ fontSize: '18px' }} /> Employee</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={URLS.SOLUTION_PROVIDER.LIST} className="align-items-center"  ><TbBulb style={{ fontSize: '22px' }} /> Solution Provider</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={'#'} className="align-items-center"  ><TbReport style={{ fontSize: '18px' }} /> Reports</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={URLS.ROLE.LIST} className="align-items-center" ><FaUserShield style={{ fontSize: '18px' }} /> Role</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={URLS.NOTIFICATION.LIST} className="align-items-center" ><Icon name='notification' size={18}/> Email & Notification</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </Nav>
            </div>


            <div className="mob-menu-wrapper d-none print-hide">
                <ul className="mob-menu d-flex">
                    <li className="nav-item-mob active">
                        <a className="active d-flex align-items-center gap-2 dashboard-menu-btn" aria-current="page" href="#"><i className="home-icon"></i> Dashboard</a>

                    </li>
                    <li className="nav-item-mob">
                        <a className="d-flex align-items-center gap-2" href="airport.html"><i className="airport-icon"></i> Airport</a>
                    </li>
                    <li className="nav-item-mob">
                        <a className="d-flex align-items-center gap-2" href="idea-list.html"><i className="usecase-icon"></i> Use Case</a>
                    </li>
                    <li className="nav-item-mob">
                        <a className="d-flex align-items-center gap-2" href="#"><i className="report-icon"></i> Reports</a>
                    </li>
                    <li className="nav-item-mob">
                        <a className="d-flex align-items-center gap-2 more-menu-btn" href="#"><i className="more-icon"></i> More</a>
                    </li>
                </ul>
                <div className="more-menu-panel">
                    <ul>
                        <li><a href="list-filter.html">Admin Employee</a></li>
                        <li><a href="solution-provider.html">Solution Provider</a></li>
                    </ul>
                </div>
                {/* <div className="dashboard-menu-panel">
                    <ul>
                        <li><a href="dashboard.html">Executive Summary</a></li>
                        <li><a href="kpi_trendingdashboard.html">KPI and Trending</a></li>
                        <li><a href="preiodcomprisonchat_dashboard.html">Period Comparison Chart</a></li>
                        <li><a href="trendingphase_chartDashboard.html">Trending by Phase Chart</a></li>
                        <li><a href="dynamicdrilldown_dashboard.html">Dynamic Drilldown</a></li>
                    </ul>
                </div> */}

            </div>

            <div className="footer py-3 mt-4 print-hide">
                <div className="container">
                    <p>
                        ©
                        <script>
                            document.write(new Date().getFullYear());
                        </script>
                        InnovationWorks</p>
                </div>
            </div>
        </>
    );
}

export default Footer;