import React, { useEffect, useState } from 'react'
import Layout from '../../../components/workflow/Layout'
import { PageMenu } from '../../../components/workflow/PageMenu'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { URLS } from '../../../_config'
import { getNameOnly, replaceUrlParams } from '../../../_config/helpers'
import { useWorkFlowApi } from '../../../_common/hooks/api/WorkFlowApiHook'
import ScoringPrioritizationListModal from '../../../modals/scoringPrioritizationList.modal'
import toast from 'react-hot-toast'
import { useUser } from '../../../_common/hooks/authHook'
import Swal from 'sweetalert2'
import { GoTrash } from "react-icons/go";
import TableLoader from '../../../components/tableLoader'
import { useUsecase } from '../../../_common/hooks/usecaseHook'
import { Button } from 'react-bootstrap'
import TitleBar from '../../../components/titleBar/TitleBar'
import Pagination from '../../../components/pagination/Pagination'
import SearchField from '../../../components/searchSection/SearchField'
import { useUserApi } from '../../../_common/hooks/api/UserApiHook'
import { GoToNextPhaseButton } from '../../../components/workflow/elements/GoToNextPhaseButton'
import GetStartDateEndDate from '../../../components/getStartDateEndDate'


export const RankingPrioritization = () => {
    const { id } = useParams();
    const workFlowApi = useWorkFlowApi();
    const usecase = useUsecase();
    const userApi = useUserApi();
    const [scoringPrioritizationList, setScoringPrioritizationList] = useState<any>(null)
    const [showAddScoreModal, setShowAddScoreModal] = useState<boolean>(false);
    const [scoreCompleted, setScoreCompleted] = useState<boolean>(false);
    const navigate = useNavigate();
    const user: any = useUser();

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [pageLimit, setPageLimit] = useState<number>(10)
    const [searchText, setSearchText] = useState<any>('');

    const [dateSubmited, setDateSubmited] = useState<boolean>(false)
    const [triggerSDED, setTriggerSDED] = useState<boolean>(false);
    const [SDEDPromise, setSDEDPromise] = useState<any>(null);

    const checkSDED = ()=>{
        let p:any = new Promise((resolve,reject)=>{
            if(dateSubmited){
                resolve(true)
            } else {
                setSDEDPromise(()=>{ return resolve})
                setTriggerSDED(true);
            }
        })
        return p;
    }

    

    const getScoringPrioritizationList = () => {
        return new Promise((resolve: any, reject: any) => {
            workFlowApi.getScoringPrioritizationByUsecase({ urlParams: { CompanyID: user.companyID, UseCaseID: id }, PageNo: page, PageSize: pageLimit }, (message: any, resp: any) => {
                setTotal(resp.totalRecords)
                setScoringPrioritizationList(resp.data?.scorerList)
                resolve(resp.data?.scorerList)
            }, (message: any) => {
                setScoringPrioritizationList([]);
                resolve([])
            })
        })

    }

    useEffect(() => {
        getScoringPrioritizationList();
    }, [id, page, searchText])

    useEffect(() => {
        if (scoringPrioritizationList?.length > 0) {
            let completed = true;
            scoringPrioritizationList.map((score: any) => {
                if (!score.isScoreComplete) { completed = false }
            })
            setScoreCompleted(completed)
        }
    }, [scoringPrioritizationList])

    const getUsecase = () => {
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: { companyID: user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { })
        })
    }
    const savePhaseChangeData = (data: any) => {
        return new Promise((resolve, reject) => {
            userApi.usecasePhaseChange(data, (message: any, resp: any) => {
                getUsecase().then(() => {
                    resolve(message)
                 }).catch((msg) => { 
                    reject(msg)
                 })
            }, (message: any) => {
                //reject(message);
                //onSuccess();
            })
        })
    }

    const phaseChange = () => {
        let formParam = {
            "useCaseID": usecase.useCaseID,
            "fkCompanyID": user.companyID,
            "nextPhase": 'Hanger Decision',
            "updatedBy": user.userID,
        };
        savePhaseChangeData(formParam).then(()=>{
            navigate(replaceUrlParams(URLS.WORKFLOW.HANGER.DECISION,{id:id}))
        })
    }

    const deleteScoreData = (scoreId: any) => {
        return new Promise((resolve, reject) => {

            workFlowApi.scoringPrioritizationDelete({
                "scoringPrioritizationUserID": scoreId,
                "fkCompanyID": user.companyID,
                "fkUseCaseID": id,
                "updatedBy": user.userID
            }, (msg: any, resp: any) => {
                setPage(1)
                getScoringPrioritizationList()
                resolve(msg)




                getScoringPrioritizationList().then((scorer: any) => {
                    if (scorer.length) {
                        let completed = true;
                        scorer.map((score: any) => {
                            if (!score.isScoreComplete) { completed = false }
                        })
                        if (completed) {
                            //phaseChange();
                        }
                    }
                });


            }, (msg: any) => {
                setScoringPrioritizationList([]);
                resolve('Deleted successfully.')
            })
        })
    }
    const deleteScore = (scoreId: any) => {
        Swal.fire({
            title: `Confirm alert!`,
            text: `Are you sure you want to delete this score?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, Delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteScoreData(scoreId), {
                    loading: 'Deleting...',
                    success: (msg: any) => {
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });

    }

    useEffect(() => {
        if(dateSubmited){
            if(SDEDPromise){
                SDEDPromise();
            }
        }
    }, [dateSubmited])


    return (
        <Layout titleBar={
            <TitleBar title={`Ranking Prioritizations | usecase : ${usecase?.useCase}`} breadcrumbs={{
                'Use case list': URLS.USE_CASE_LIST,
                'Use case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                'Ranking Prioritizations': '#'
            }} >
                {/* <Timer startTime={new Date()} /> */}
            </TitleBar>
        }>
            <PageMenu pageTab="RankingPrioritization" />

            {showAddScoreModal && <ScoringPrioritizationListModal
                show={showAddScoreModal}
                onClose={setShowAddScoreModal}
                receiveNewData={(data) => { getScoringPrioritizationList() }}
            />}

            <div className="white-box">
                <div className="form-area">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2 className="h5">Who is Scoring this Use Case</h2>
                        <SearchField onSearch={(str: any) => {
                            setSearchText(str)
                            setPage(1)
                        }} />
                        {scoreCompleted ? null : <Button onClick={() => { setShowAddScoreModal(true) }}>Add Scorer</Button>}
                    </div>

                    <hr />


                    <div className="table-responsive list-table ">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" className="w-10 text-center">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Role</th>
                                    <th scope="col" className="text-center">Score</th>
                                    <th scope="col" className="text-center">Scoring Complete</th>
                                    <th scope="col" className="w-10">Operations</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    scoringPrioritizationList && scoringPrioritizationList.length > 0 &&
                                    scoringPrioritizationList?.map((empRow: any, index: any) => (
                                        <tr key={`scoringPrioritization${index}`}>
                                            <td className="text-center">{((page -1) * 10) + index + 1}

                                            </td>
                                            <td>{getNameOnly(empRow?.userFullName)}</td>
                                            <td>{empRow?.roleName}</td>
                                            <td className='text-center'>{empRow?.score}</td>
                                            <td className="text-center">
                                                <span className="badge badge-secondary">
                                                    {empRow?.isScoreComplete ? 'Yes' : 'No'}
                                                </span>
                                            </td>
                                            <td>
                                                <GoTrash className='react-icon-grey cursor-pointer' onClick={() => deleteScore(empRow?.scoringPrioritizationUserID)} />

                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <TableLoader data={scoringPrioritizationList} />

                        <Pagination total={total} page={page} setPage={setPage} pageLimit={pageLimit} />

                    </div>

                </div>
                <div className="align-items-end bg-white pb-3 pt-2 rounded-1 row sticky-bottom">
                    <div className="col-md-8">
                        <GetStartDateEndDate submited={setDateSubmited} submitTrigger={triggerSDED} dataFor={{
                            useCaseID: id,
                            fkCompanyID: user.companyID,
                            phaseName: 'Ranking and Prioritization',
                            startDate: '',
                            completeDate: '',
                            updatedBy: user.userID
                        }}
                        />
                    </div>
                    <div className="col-md-4">
                        <div className='col-12 d-flex justify-content-end gap-3'>
                            <GoToNextPhaseButton usecaseID={id} nextPhase='Hanger Decision' permission={checkSDED} onSuccess={() => {
                                navigate(replaceUrlParams(URLS.WORKFLOW.HANGER.DECISION, { id: id }))
                            }} />
                        </div>
                    </div>
                </div>
            </div>

        </Layout>


    )
}
