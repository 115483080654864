import React, { useEffect, useState } from 'react'
import Layout from '../../../components/workflow/Layout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { URLS } from '../../../_config'
import { replaceUrlParams } from '../../../_config/helpers'
import { useWorkFlowApi } from '../../../_common/hooks/api/WorkFlowApiHook'
import { useUser } from '../../../_common/hooks/authHook'
import AddDayInLifeMemberModal from '../../../modals/addDayInLifeMember.modal'
import { PageMenuInTheAir } from '../../../components/workflow/PageMenuInTheAir'
import DynamicForm from '../../../components/dynamicForm/dynamicForm'
import { useUserApi } from '../../../_common/hooks/api/UserApiHook'
import TitleBar from '../../../components/titleBar/TitleBar'
import Timer from '../../../components/timer/Timer'
import { useUsecase } from '../../../_common/hooks/usecaseHook'


export const FieldAssistance = () => {
    const { id } = useParams();
    const user: any = useUser();
    const navigate = useNavigate();
    const userApi = useUserApi();
    const usecase: any = useUsecase();

    const [addMemberModal, setAddMemberModal] = useState<boolean>(false)
    const [members, setMembers] = useState<any[] | null>(null)

    const getUsecase = () => {
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: {companyID:user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { reject(message) })
        })

    }

    const moveToNextPhase = () => {
        return new Promise((resolve, reject) => {
            let formParam = {
                "useCaseID": id,
                "fkCompanyID": user.companyID,
                "nextPhase": 'Go/No Go',
                "updatedBy": user.userID,
            };
            userApi.usecasePhaseChange(formParam, (message: any, resp: any) => {
                getUsecase().then(() => { resolve(message) }).catch((msg) => { reject(msg) })
            }, (message: any, resp: any) => {
                getUsecase().then(() => { resolve(message) }).catch((msg) => { reject(msg) })
                //reject(message);
            })
        })
    }

    const getMembers = () => {
        setMembers([])
    }

    useEffect(() => {
        getMembers()
    }, [])

    return (

        <Layout titleBar={
            <TitleBar title={`Field Acceptance | usecase : ${usecase?.useCase}`} breadcrumbs={{
                'Use case list': URLS.USE_CASE_LIST,
                'Use case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                'Field Acceptance': '#'
            }} >
                <Timer startTime={new Date()} />
            </TitleBar>
        }>
            {/* <PageMenu /> */}
            <PageMenuInTheAir pageTab="Field Acceptance" />
            <div className="white-box">
                <DynamicForm sded={true} _workflow='in-the-air' _module='field-assistance' _form='field-assistance' useCaseID={id} editMode={true} section='In the Air' phase='Field Acceptance' onSubmitSuccess={(edit: any) => {
                    moveToNextPhase().then(() => {
                        navigate(replaceUrlParams(URLS.WORKFLOW.LANDED.GONOGO, { id: id }))
                    })

                }} />

            </div>
            <AddDayInLifeMemberModal
                show={addMemberModal}
                onClose={setAddMemberModal}
                receiveNewData={(members: any) => { }}
            />
        </Layout>


    )
}
