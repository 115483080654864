import React, { useEffect, useState } from "react";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link, useLocation, useNavigate } from "react-router-dom";
import './usecaseList.scss';
import { URLS } from "../../_config";
import { replaceUrlParams } from "../../_config/helpers";
import { showDate } from "../../_config/functions";
import TableLoader from "../tableLoader";
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";
import { usecaseFilterData } from "../../_config/dataTypes";
import Pagination from "../pagination/Pagination";
import Icon from "../icon";
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import { ConfirmAlert } from "../../_common/confirmAlert";



interface props {
    filter?: usecaseFilterData
}

export function UsecaseList({ filter }: props) {
    const [useCaseList, setUseCaseList] = useState<any>(null);
    const userApi = useUserApi();
    const user: any = useUser();

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [pageLimit, setPageLimit] = useState<number>(10)
    const [rankSort, setRankSort] = useState<number>(0)

    const location = useLocation();
    const navigate = useNavigate()
    const permissions: any = useUserPermissions();

    const { showLoader, hideLoader } = useAppLoader()



    const getUseCaseList = () => {
        setUseCaseList(null);
        userApi.useCaseList({
            fkCompanyID: user.companyID,
            pageNo: page,
            rankSorting: rankSort,
            pageSize: pageLimit, ...filter
        }, (message: any, resp: any) => {
            setTotal(resp.totalRecords)
            setUseCaseList(resp.data.usecaseList.filter((usecase: any) => {
                if (usecase.fkStatusID == 18 && usecase.addedBy != user.userID) {
                    return false
                } else {
                    return true;
                }
            }));
            hideLoader()
        }, (message: any, resp: any) => {
            hideLoader()
            setUseCaseList([])
        })

    }

    const deleteUsecase = (params:any) => {
        return new Promise((resolve:any,reject:any)=>{
            userApi.deleteUsecase(params, resolve, reject)
        })
    }
    const archiveUsecase = (params:any) => {

        return new Promise((resolve, reject) => {
            userApi.postUsecaseApproved(params, (message: any, resp: any) => {
                resolve(message);
            }, (message: any, resp: any) => {
                reject(message);
            })
        })
    }



    useEffect(() => {
        if (useCaseList !== null) {
            setPage(1)
            getUseCaseList()
        }

    }, [filter]);

    useEffect(() => {
        if (useCaseList !== null) {
            getUseCaseList()
        }
    }, [page]);

    useEffect(() => {
        if (useCaseList !== null) {
            setPage(1);
            getUseCaseList()
        }
    }, [rankSort])

    useEffect(() => {
        let qparams: any = new URLSearchParams(location.search);
        if (qparams.get('reload') == 'true') {
            if (useCaseList !== null) {
                getUseCaseList();
                navigate(URLS.USE_CASE_LIST)
            }
        }
    }, [location])
    useEffect(() => {
        getUseCaseList();
    }, [])

    const getSortIcon = (sort: number) => {
        switch (sort) {
            case 0: return <Icon name='unsorted' size={18} />;
            case 1: return <Icon name='sort-up' size={18} />;
            case 2: return <Icon name='sort-down' size={18} />;
            default: return null
        }
    }



    return (
        <div className="table-responsive list-table full-width">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" className="w-5 text-center">Use Case ID</th>
                        <th scope="col"></th>
                        <th scope="col">Use Case Name</th>
                        <th scope="col">Airport</th>
                        <th scope="col">Captain</th>
                        <th scope="col">Section</th>
                        <th scope="col">Phase</th>
                        <th scope="col" className="d-flex cursor-pointer" onClick={() => {
                            let rs: number = rankSort + 1;
                            if (rs > 2) { rs = 0 }
                            setRankSort(rs)
                        }}>Rank {getSortIcon(rankSort)}</th>
                        <th scope="col">Likes</th>
                        <th scope="col">Strategic Value</th>
                        <th scope="col">Submission Date</th>
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {useCaseList && useCaseList.length > 0 && (
                        useCaseList.map((usecase: any, i: any) => (
                            <tr key={`usecase-${usecase.useCaseID}-${i}`}>
                                <th className="text-center vr-middle">{usecase.useCaseID}</th>
                                <td>
                                    <div className="table-pic">
                                        <Link to={replaceUrlParams(URLS.USECASE_DETAILS, { id: usecase.useCaseID })}>
                                            <img src={usecase.imgURL} alt="" className="table-pic-img" />
                                        </Link>
                                    </div>
                                </td>
                                <td className="use-case-name">
                                    <h6>
                                        <Link to={replaceUrlParams(URLS.USECASE_DETAILS, { id: usecase.useCaseID })} className="text-dark">
                                            {usecase.useCase}
                                        </Link>
                                    </h6>
                                    <p> {usecase.addedByName}  </p>
                                </td>
                                <td>{usecase.airPort ? usecase.airPort : '--'}</td>
                                <td>{usecase.captain ? usecase.captain : '--'}</td>
                                <td>{usecase.section ? usecase.section : '--'}</td>
                                <td>{usecase.currentPhase ? usecase.currentPhase : '--'}</td>
                                <td className="text-center">{usecase.rank ? usecase.rank : '--'}</td>
                                <td className="text-center">{usecase.likes}</td>
                                <td>{usecase.strategicValue ? usecase.strategicValue : '--'}</td>
                                <td>{showDate(usecase.addedDate)}</td>
                                <td className="text-center">
                                    <span className={`tags tags-${usecase.status.toLowerCase()}`}>
                                        {usecase?.fkStatusID == 10 ? (
                                            'Under Review'
                                        ) : (
                                            usecase.status
                                        )}
                                    </span>
                                </td>
                                <td>
                                    <div className="d-flex gap-2 align-items-center">
                                        {permissions?.menus.usecase.delete &&
                                            <Icon name="delete" onClick={() => {
                                                ConfirmAlert.delete('Usecase', deleteUsecase, {
                                                    "fkUseCaseID": usecase.useCaseID,
                                                    "fkUserID": user.userID,
                                                    "fkCompanyID": user.companyID
                                                }).then(() => {
                                                    getUseCaseList();
                                                })
                                            }} />
                                        }

                                        {(user.roleKey == 'INNOVATION_MANAGER_ROLE' || user.roleKey == 'ADMIN') && usecase?.fkStatusID != 9 &&
                                            <Icon name="archive" size={22} onClick={() => {
                                                ConfirmAlert.archive('Usecase', archiveUsecase, {
                                                    "fkRoleID": user?.roleID,
                                                    "fkCompanyID": user?.companyID,
                                                    "fkUseCaseID": usecase.useCaseID,
                                                    "fkUserID": user.userID,
                                                    "isApproved": false,
                                                    "reason": '',
                                                }).then(() => {
                                                    getUseCaseList();
                                                })
                                            }} />
                                        }

                                    </div>
                                </td>
                            </tr>
                        ))
                    )
                    }

                </tbody>
            </table>

            <TableLoader data={useCaseList} />
            {useCaseList ? <Pagination total={total} page={page} setPage={setPage} pageLimit={pageLimit} /> : null}

        </div>
    );
}
