import React, { HtmlHTMLAttributes } from 'react'
import { BiSolidLike } from 'react-icons/bi';
import { BsDownload, BsFillShieldLockFill } from 'react-icons/bs';
import { FaCheckSquare, FaTimes } from 'react-icons/fa';
import { MdDeleteOutline, MdOutlineModeEdit } from 'react-icons/md';
import { LuAlarmClock } from "react-icons/lu";
import { IoIosArrowBack, IoIosArrowForward, IoMdArchive, IoMdCloudUpload } from "react-icons/io";
import { FaBell, FaWpforms } from 'react-icons/fa6';
import { RiMailSendLine, RiShieldKeyholeLine } from "react-icons/ri";
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from "react-icons/ti";
import { IoNotificationsOutline } from 'react-icons/io5';
import { GrScorecard } from 'react-icons/gr';
import { AiOutlineDelete } from "react-icons/ai";
import { GoArchive } from 'react-icons/go';


type nameType = 'delete' | 'edit' | 'download' | 'like' | 'checked' | 'clock' | 'arrow-right'
    | 'arrow-left' | 'form' | 'times' | 'shield-lock' | 'shield-lock-outline' | 'file-upload'
    | 'unsorted' | 'sort-up' | 'sort-down' | 'bell' | 'notification' | 'score-card' | 'archive' | 'send-email'

interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    name: nameType;
    size?: any;
    className?: string;
    onClick?: (e: any) => void
}
const Icon: React.FC<props> = ({
    children,
    name,
    size = '24',
    className = '',
    onClick,
    ...args
}) => {


    const getIconByName = (name: nameType) => {
        switch (name) {
            case 'delete': return <AiOutlineDelete style={{ fontSize: `${size}px` }} />;
            case 'edit': return <MdOutlineModeEdit style={{ fontSize: `${size}px` }} />;
            case 'download': return <BsDownload style={{ fontSize: `${size}px` }} />;
            case 'like': return <BiSolidLike style={{ fontSize: `${size}px` }} />;
            case 'checked': return <FaCheckSquare style={{ fontSize: `${size}px` }} />;
            case 'clock': return <LuAlarmClock style={{ fontSize: `${size}px` }} />;
            case 'arrow-right': return <IoIosArrowForward style={{ fontSize: `${size}px` }} />;
            case 'arrow-left': return <IoIosArrowBack style={{ fontSize: `${size}px` }} />;
            case 'form': return <FaWpforms style={{ fontSize: `${size}px` }} />;
            case 'times': return <FaTimes style={{ fontSize: `${size}px` }} />;
            case 'shield-lock': return <BsFillShieldLockFill style={{ fontSize: `${size}px` }} />;
            case 'shield-lock-outline': return <RiShieldKeyholeLine style={{ fontSize: `${size}px` }} />;
            case 'file-upload': return <IoMdCloudUpload style={{ fontSize: `${size}px` }} />;
            case 'sort-up': return <TiArrowSortedUp style={{ fontSize: `${size}px` }} />;
            case 'sort-down': return <TiArrowSortedDown style={{ fontSize: `${size}px` }} />;
            case 'unsorted': return <TiArrowUnsorted style={{ fontSize: `${size}px` }} />;
            case 'bell': return <FaBell style={{ fontSize: `${size}px` }} />;
            case 'notification': return <IoNotificationsOutline style={{ fontSize: `${size}px` }} />;
            case 'score-card': return <GrScorecard style={{ fontSize: `${size}px` }} />;
            case 'archive': return <GoArchive style={{ fontSize: `${size}px` }} />;
            case 'send-email': return <RiMailSendLine style={{ fontSize: `${size}px` }} />;
            default: return null;
        }
    }

    return (
        <span onClick={onClick ? onClick : (e: any) => { }} className={`${className} ${(onClick) ? 'cursor-pointer' : ''}`}>
            {getIconByName(name)}
        </span>
    )
}

export default Icon;
