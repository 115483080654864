import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import Select from 'react-select'
import Error from '../../components/elements/formFieldError'
import { useUser } from '../../_common/hooks/authHook'
import { URLS } from '../../_config'
import { getSelectedValue } from '../../_config/helpers'

export const AirportAddEdit = () => {
    const userInfo: any = useUser();
    const navigate = useNavigate();
    const userApi = useUserApi();
    const { id } = useParams();

    const [allOwnerList, setAllOwnerList] = useState<Array<any>>([]);


    type Inputs = {
        fkUserID: any
        name: string
        description: string
        fkCompanyID: number
        fkTenantID: number
    }

    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Inputs>()

    const roleConfigList = () => {
        let key = 'INNOVATOR';
        console.log('companyIDcompanyID', userInfo?.companyID)
        userApi.getConfigByKey({ urlParams: { key: key, companyID: userInfo?.companyID } }, (message: any, resp: any) => {
            ownerList(resp.data.value)
        }, (message: any, resp: any) => {
            // Handle error...
        });
    };


    const ownerList = (ownerOptions: string) => {
        let param = {
            RoleName: ownerOptions,
            FKCompanyID: userInfo?.companyID // For fontend user
            //FKCompanyID: 0 // For supper admin user
        }

        userApi.getUsersByRoleName(param, (message: any, resp: any) => {
            setAllOwnerList(resp.data.users.map((user: any) => {
                user.value = user.userID;
                user.label = `${user.firstName} ${user.lastName}`;
                return user;
            }));
        }, (message: any, resp: any) => {
            // Error...
        })
    }

   

    const getAirportByID = (id: any) => {
        let param = {
            AirportID: id,
            FKCompanyID: userInfo?.companyID
        }

        userApi.airportDetails(param, (message: any, resp: any) => {
            reset({
                fkUserID: getSelectedValue(resp.data.fkUserID, "value", allOwnerList),
                name: resp.data.name,
                description: resp.data.description,
            })
            console.log('resp', resp);

        }, (message: any, resp: any) => {
            // Error...
        })
    }

    useEffect(() => {
        roleConfigList();
    }, []);



    useEffect(() => {
        if (id && allOwnerList?.length > 0) {
            getAirportByID(id)
        }
    }, [id, allOwnerList]);

    const submitData = (data: any) => {
        
        return new Promise((resolve, reject) => {
            let formParam: any = {
                fkUserID: data.fkUserID.value,
                name: data.name,
                description: data.description,
                fkCompanyID: userInfo?.companyID,
                fkTenantID: userInfo?.tenantID,
            };
            if (id) {
                formParam.airportID = id;
                userApi.airportEdit(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any) => {
                    reject(message)
                })
            } else {
                userApi.airportAdd(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any) => {
                    reject(message)
                })
            }
        })
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        console.log('data', data);
        //return false;
        toast.promise(
            submitData(data), {
            loading: 'Loading...',
            success: (msg: any) => { navigate('/airport/list'); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    //console.log('ownerOptions',ownerOptions, allOwnerList)

    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li><Link to={URLS.AIRPORT.LIST}>Airport List</Link></li>
                                    <li>{(id == undefined) ? 'Create' : 'Edit'} Airport</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0"> {(id == undefined) ? 'Create' : 'Edit'} Airport</h1>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="col-md-6">
                                            <label htmlFor="airportname" className="form-label">Airport Name</label>
                                            <input type="text" {...register("name", { required: "Airport name is required" })} className="form-control" placeholder="Put the name" id="airportname" />
                                            <Error error={errors.name} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Assign Owner</label>

                                            <Controller
                                                name="fkUserID"
                                                control={control}
                                                rules={{ required: 'Owner is required' }}
                                                render={({ field }) => (
                                                    <div>
                                                        <Select
                                                            {...field}
                                                            options={allOwnerList}
                                                            isMulti={false}
                                                        />
                                                        <Error error={errors.fkUserID} />
                                                    </div>
                                                )}
                                            />

                                        </div>
                                        <div className="col-12 mt-3">
                                            <label htmlFor="description" className="form-label">Description</label>
                                            <textarea id="description" {...register("description", { required: "Description is required" })} className="form-control" rows={5} placeholder="What is the mission your Airport is trying to accomplish?"></textarea>
                                            <Error error={errors.description} />
                                        </div>

                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <Link to={URLS.AIRPORT.LIST} className="btn btn-dark d-flex align-items-center gap-2">Back</Link>
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="pt-5">
                                <img src="./../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
