/*
    This page used for List All Notification
*/
import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import toast from 'react-hot-toast';
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";

import { MdOutlineModeEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import TableLoader from "../../components/tableLoader";
import TitleBar from "../../components/titleBar/TitleBar";
import { URLS } from "../../_config";
import Icon from "../../components/icon";
import { replaceUrlParams } from "../../_config/helpers";


export const NotificationList = () => {

    const [notificationList, setNotificationList] = useState<any>(null);
    const userApi = useUserApi();
    const userInfo: any = useUser();
    const permissions: any = useUserPermissions();


    const getNotificationList = () => {
        userApi.notificationList({ urlParams: { PageNo: 1, PageSize: 100 } }, (message: any, resp: any) => {
            setNotificationList(resp.data.notificationTemplates);
        }, (message: any) => {
            setNotificationList([])
        })

    }

    useEffect(() => {
        getNotificationList()
    }, []);



    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={'Email & Notifications'} breadcrumbs={{
                        'Settings': '#',
                        'Email & Notifications': '#'
                    }} >

                    </TitleBar>

                    <div className="row">
                        <div className="col-sm-12">

                            <div className="white-box px-4 pt-3">
                                <div className="table-responsive list-table full-width">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="align-middle">
                                                <th scope="col" className="text-center">#</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Subject</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {notificationList && notificationList.length > 0 && (
                                                notificationList.map((item: any, index: number) => (
                                                    <tr key={item.roleID} className="align-middle h-60">
                                                        <td className="text-center">{index + 1}</td>
                                                        <td >{item.title}</td>
                                                        <td>{item.subject}</td>
                                                        <td className="w-150px">
                                                            {permissions?.menus.settings.write &&
                                                                <Link to={replaceUrlParams(URLS.NOTIFICATION.EDIT, { id: item.notificationTemplateID })} className="react-icon-grey"><Icon name="edit" /></Link>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            )}

                                        </tbody>
                                    </table>
                                    <TableLoader data={notificationList} />
                                </div>

                                {/*  <Pagination /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    )
}
