import React, { HtmlHTMLAttributes, useMemo, useState } from 'react'

interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    onSearch: (str: string) => void,
}
const SearchField: React.FC<props> = ({
    children,
    onSearch,
    ...args
}) => {
    const [searchTerm, setSearchTerm] = useState("");

    // const debouncedResults = useMemo(() => {
    //     return debouce(handleChange, 300);
    //   }, []);

    //   const handleChange = (e) => {
    //     setSearchTerm(e.target.value);
    //   };

    return (
        <div >

            <input type="search" className='form-control'
                onChange={(e: any) => { onSearch(e.target.value) }} 
                placeholder='Search...'/>

        </div>
    )
}

export default SearchField;
