import Layout from '../../../components/workflow/Layout'
import { PageMenu } from '../../../components/workflow/PageMenu'
import { useNavigate, useParams } from 'react-router-dom'
import DynamicForm from '../../../components/dynamicForm/dynamicForm'
import { replaceUrlParams } from '../../../_config/helpers'
import { URLS } from '../../../_config'
import { useUserApi } from '../../../_common/hooks/api/UserApiHook'
import { useUser } from '../../../_common/hooks/authHook'
import TitleBar from '../../../components/titleBar/TitleBar'
import Timer from '../../../components/timer/Timer'
import { useUsecase } from '../../../_common/hooks/usecaseHook'


export const ChallengeStatements = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const usecase: any = useUsecase();
    const userApi = useUserApi();
    const userInfo: any = useUser();

    const getUsecase = () => {
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: {companyID:userInfo.companyID, id: id, userID: userInfo.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { reject(message) })
        })

    }

    const moveToNextPhase = () => {
        return new Promise((resolve, reject) => {
            let formParam = {
                "useCaseID": id,
                "fkCompanyID": userInfo.companyID,
                "nextPhase": 'Solution Framing',
                "updatedBy": userInfo.userID,
            };

            userApi.usecasePhaseChange(formParam, (message: any, resp: any) => {
                getUsecase().then(() => { resolve(message) }).catch((msg) => { reject(msg) })
            }, (message: any, resp: any) => {
                getUsecase().then(() => { resolve(message) }).catch((msg) => { reject(msg) })
                //reject(message);
            })
        })
    }

    return (
        <Layout titleBar={
            <TitleBar title={`Challenge Statements | usecase : ${usecase?.useCase}`} breadcrumbs={{
                'Use case list': URLS.USE_CASE_LIST,
                'Use Case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                'Challenge Statements': '#'
            }} >
                <Timer startTime={new Date()} />
            </TitleBar>
        }>
            <PageMenu pageTab="ChallengeStatement" />

            <div className="white-box">
                <DynamicForm _workflow='hangar' useCaseID={id} _module='create-challenge' editMode={true} _form='challenge' section='Hanger' phase='Challenge Statement' sded={true} onSubmitSuccess={(edit: boolean) => {
                    if(edit){
                        moveToNextPhase().then(() => {
                            navigate(replaceUrlParams(URLS.WORKFLOW.HANGER.SOLUTIONS, { id: id }))
                        })
                    }
                }} />

                {/* <hr />
                <div className="row">
                    <div className='col-12 d-flex justify-content-end gap-3'>
                        <GoToNextPhaseButton usecaseID={id} nextPhase='Solution Framing' onSuccess={() => {
                            navigate(replaceUrlParams(URLS.WORKFLOW.HANGER.SOLUTIONS, { id: id }))
                        }} />
                    </div>
                </div> */}
            </div>
        </Layout>
    )
}
