import toast from "react-hot-toast";

export const replaceUrlParams = (url: string, params: any) => {
	Object.keys(params).map((param) => {
		url = url.replace(`:${param}`, params[param])
	})
	return url
}

export const capitalizeFirstLetter = (str: string) => {
	const firstSpaceIndex = str.indexOf(' ');
	if (firstSpaceIndex !== -1 && firstSpaceIndex < str.length - 1) {
		// Extract the first letter after the first space and make it uppercase
		return str.charAt(firstSpaceIndex + 1).toUpperCase();
	} else {
		// If there's no space or it's the last character, use the first letter and make it uppercase
		return str.charAt(0).toUpperCase();
	}
};


export const getSelectedValue = (value: any, node: string, options: any[]) => {
    let getValue = (val: any) => {
        let result = null;
        options && options.map((option: any) => {
            if (option[node] == val) {
                result = option;
            }
        })
        return result;
    }
    if (Array.isArray(value)) {
        let selected: any = []
        value.map((val: any) => {
            selected.push(getValue(val))
        })
        return selected;
    } else {
        return getValue(value);
    }
}

export const getRelativeListWithMultiSelected = (parentList: any[], childList: any[], selectedChild: any[], parentKey: string, childKey: string, childId: string) => {

    let list: any = [];
    let selected: any = [];


    try {
        parentList && parentList?.map((parent: any) => {
            childList?.map((child: any) => {
                if (parent[parentKey] == child[childKey]) {
                    list.push(child);
                }
            })
        })
        selectedChild && selectedChild?.map((child: any) => {
            list.map((fChild: any) => {
                if (fChild[childId] == child[childId]) {
                    selected.push(child)
                }
            })
        });

    } catch (error) {

    }



    return {
        list, selected
    };
}

export const onReachedEnd = (callback:any) => {
	document.body.onscroll = (e:any) => {
		let height = window.innerHeight;
		let scrollHeight = document.body.offsetHeight;
		let scroll = window.scrollY;

		if (height + scroll + 60 >= scrollHeight) {
			callback()
		} else {
			return;
		}
		
	}
}



export const mergeListData = (identifire:any, mergeNode:any, dataList:any[]) => {
	let data:any[] = [];
	dataList.map((object:any)=>{
		if(data.filter((_data:any)=>{ return _data[identifire] == object[identifire]}).length > 0){
			data.map((obj:any)=>{
				if(obj[identifire] == object[identifire]){
					if(Array.isArray(obj[mergeNode])){
						obj[mergeNode] = [...obj[mergeNode], object[mergeNode]]
					} else {
						obj[mergeNode] = [obj[mergeNode], object[mergeNode]]
					}
					
				} 

				return obj;
				
			})
		} else {
			data.push(object)
		}
	})
	console.log('merge data =>',data)
	return data;
	
};

export const secondsToDhms = (seconds: number) => {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor(seconds % (3600 * 24) / 3600);
	var m = Math.floor(seconds % 3600 / 60);
	var s = Math.floor(seconds % 60);

	var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
	var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
	var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
	var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
	return dDisplay + hDisplay + mDisplay + sDisplay;
}

export const isImageUrl = (url: string) => {
	return /\.(jpg|jpeg|png|webp|gif|svg)$/.test(url);
}


export const getNameOnly = (fullName: string) => {
	if(fullName){
		let n:any = fullName.split(' ');
		if(n.length > 2){
			return `${n[1]} ${n[2]}`
		}
	}
	return fullName
}

export const toastHandeler = (func: any, data:any, message:string) => {
	return new Promise((resolve:any, reject:any)=>{
		toast.promise(func(data), {
            loading: message,
            success: (msg: any) => {
                resolve(msg);
                return <b>{msg}</b>
            },
            error: (msg: any) => {reject(msg); return <b>{msg}</b> },
        });
	})
}






