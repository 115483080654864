import React, { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form";
import Error from "./elements/formFieldError";
import { useWorkFlowApi } from "../_common/hooks/api/WorkFlowApiHook";
import { showDate } from "../_config/functions";

type SDEDPOST = {
    useCaseID:any,
    fkCompanyID: any,
    phaseName: string,
    startDate: string,
    completeDate: string,
    updatedBy: any
}

interface props {
    dataFor:SDEDPOST
    submitTrigger?:boolean
    submited?:(s:boolean)=>void
    readOnly?:boolean
}

export default function GetStartDateEndDate({dataFor, submited, submitTrigger = false, readOnly = false}: props) {
    const [submitedStatus,setSubmitedStatus] = useState<boolean>(false)
    const { control,  handleSubmit, reset, setValue, getValues, formState: { errors, isValid }, } = useForm();

    const workflowApi = useWorkFlowApi();
    const formRef:any = useRef();

    const getStartDateEndDate = () => {
        workflowApi.getStartDateEndDate({urlParams:{
            companyID:dataFor.fkCompanyID,
            usecaseID:dataFor.useCaseID,
            phaseName:dataFor.phaseName
        }},(message:any,resp:any)=>{
            if(resp.data.startDate != '' && resp.data.completeDate != ''){
                setSubmitedStatus(true)
            }
            reset({
                startDate:resp.data.startDate,
                endDate:resp.data.completeDate
            })
            
        },(message:any)=>{
            
        })
       
    }


    const handleOnSubmit = (data: any) => {
        workflowApi.updateStartDateEndDate({...dataFor,...{startDate:showDate(data.startDate, 'YYYY-MM-DD'), completeDate:showDate(data.endDate, 'YYYY-MM-DD')}},(message:any,resp:any)=>{
            setSubmitedStatus(true)
            //toast.success(message)
        },(message:any)=>{
            //toast.error(message)
        })
    }

    useEffect(()=>{
        if(submitTrigger && formRef.current){
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        }
    },[submitTrigger])

    useEffect(()=>{
        if(submited){
            submited(submitedStatus)
        }
    },[submitedStatus])

    useEffect(()=>{
        getStartDateEndDate();
    },[])


    return (
        <form ref={formRef} className="row" onSubmit={handleSubmit(handleOnSubmit)} >
            <div className={'col-md-6'} >
                <label htmlFor="" className="form-label">Start Date</label>
                <Controller
                    defaultValue=""
                    control={control}
                    name={'startDate'}
                    rules={{ required: 'Start date is required' }}
                    render={({ field: { onChange, value, ref } }) => (
                        <input readOnly={readOnly} type="date" className="form-control" placeholder='yyyy-mm-dd' ref={ref} onChange={onChange} value={value} />
                    )}
                />
                <Error error={errors['startDate']} />
            </div>
            <div className={'col-md-6'} >
                <label htmlFor="" className="form-label">End Date</label>
                <Controller
                    defaultValue=""
                    control={control}
                    name={'endDate'}
                    rules={{ required: 'End date is required' }}
                    render={({ field: { onChange, value, ref } }) => (
                        <input type="date" className="form-control" placeholder='yyyy-mm-dd' ref={ref} onChange={onChange} value={value} readOnly={readOnly} />
                    )}
                />
                <Error error={errors['endDate']} />
            </div>
        </form>
    )
}



