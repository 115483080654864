import { all, takeEvery, takeLatest } from 'redux-saga/effects' // fork, take,  takeEvery, takeLeading
import { SAGA_ACTIONS } from '../../_config'
import * as userSaga from './userSaga'
import * as formSaga from './formSaga'
import * as masterSaga from './masterSaga'
import * as workFlowSaga from './workFlowSaga'
/* import * as merchantSaga from './merchantSaga'
import * as productsSaga from '../sagas/payee/productsSaga'
import * as sitemasterSaga from '../sagas/common/sitemasterSaga'
import * as hppSaga from './hppSaga'
import * as accountsSaga from './accountsSaga'
import * as paymentMethodSaga from './paymentMethodSaga'
import * as invoicesSaga from './invoicesSaga'
import * as productSaga from './productSaga' */

export function* sagas() {
  yield all([
    takeLatest(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
    takeLatest(SAGA_ACTIONS.USER.LOGIN_SSO, userSaga.loginSSO),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, userSaga.forgotPassword),
    takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, userSaga.resetPassword),
    takeEvery(SAGA_ACTIONS.USER.GET_MEMBER_DETAILS, userSaga.getMemberDetails),
    takeEvery(SAGA_ACTIONS.USER.EMPLOYEE_LIST, userSaga.employeeList),
    takeEvery(SAGA_ACTIONS.USER.DELETE, userSaga.deleteEmployee),
    takeEvery(SAGA_ACTIONS.USER.GET_FORM_CONFIG, userSaga.getFormConfig),
    takeLatest(SAGA_ACTIONS.USER.SUBMIT_DYNAMIC_FORM, userSaga.submitDynamicFrom),
    takeLatest(SAGA_ACTIONS.USER.EMPLOYEE_ACTIVE_INACTIVE, userSaga.memberActivation),

    takeLatest(SAGA_ACTIONS.USER.ADD_EMPLOYEE, userSaga.addEmployee),
    takeLatest(SAGA_ACTIONS.USER.SEND_INVITATION, userSaga.sendInvitation),
    takeLatest(SAGA_ACTIONS.USER.USER_DETAILS, userSaga.userDetails),
    takeLatest(SAGA_ACTIONS.USER.USER_UPDATE, userSaga.userUpdate),
    takeLatest(SAGA_ACTIONS.USER.USER_CHANGE_STATUS, userSaga.userChangeStatus),

    takeLatest(SAGA_ACTIONS.USER.ROLE_ADD, userSaga.addRole),
    takeLatest(SAGA_ACTIONS.USER.ROLE_EDIT, userSaga.editRole),    
    takeEvery(SAGA_ACTIONS.USER.ROLE_DETAILS, userSaga.detailsRole),
    takeLatest(SAGA_ACTIONS.USER.ROLE_LIST, userSaga.listRole),
    takeLatest(SAGA_ACTIONS.USER.ROLE_DELETE, userSaga.deleteRole),
    takeLatest(SAGA_ACTIONS.USER.USE_CASE_LIST, userSaga.useCaseList),
    takeLatest(SAGA_ACTIONS.USER.USE_CASE_DETAILS, userSaga.useCaseDetails),
    takeLatest(SAGA_ACTIONS.USER.USE_CASE_DELETE, userSaga.deleteUsecase),
    takeLatest(SAGA_ACTIONS.USER.CHANGE_AIRPORT_RUNWAY_CAPTAINS, userSaga.changeAirportRunwayCaptains),
    takeLatest(SAGA_ACTIONS.USER.AIRPORT_LIST, userSaga.airportList),
    takeLatest(SAGA_ACTIONS.USER.AIRPORT_ADD, userSaga.airportAdd),
    takeEvery(SAGA_ACTIONS.USER.BY_ROLE_NAME, userSaga.getUsersByRoleName),
    takeEvery(SAGA_ACTIONS.USER.CONFIG_BY_ROLE_NAME, userSaga.getConfigByKey),
    takeEvery(SAGA_ACTIONS.USER.CONFIG_BY_COMPANY_ID, userSaga.getConfigByCompany),
    takeLatest(SAGA_ACTIONS.USER.AIRPORT_EDIT, userSaga.airportEdit),
    takeLatest(SAGA_ACTIONS.USER.AIRPORT_DETAILS, userSaga.airportDetails),
    takeLatest(SAGA_ACTIONS.USER.AIRPORT_DELETE, userSaga.airportDelete),
    takeLatest(SAGA_ACTIONS.USER.WORKFLOW_STATUS_LOOKUPS_ALL, userSaga.getWorkFlowStatusLookUpsAll),
    takeLatest(SAGA_ACTIONS.USER.GET_RUNWAY_BY_AIRPORT, userSaga.getRunwayByAirport),
    takeLatest(SAGA_ACTIONS.USER.POST_USECASE_REVIEW_DATA, userSaga.postUsecaseReviewData),
    takeLatest(SAGA_ACTIONS.USER.GET_NITIFICATIONS, userSaga.getNotifications),
    takeLatest(SAGA_ACTIONS.USER.GET_NITIFICATION_DETAILS, userSaga.getNotificationDetails),


    takeEvery(SAGA_ACTIONS.FORM.GET_WORKFLOWS, formSaga.getWorkflows),
    takeEvery(SAGA_ACTIONS.FORM.GET_MODULES, formSaga.getModules),
    takeEvery(SAGA_ACTIONS.FORM.GET_FORMS, formSaga.getForms),
    takeEvery(SAGA_ACTIONS.FORM.GET_CONTROL_TYPES, formSaga.getControlTypes),
    takeEvery(SAGA_ACTIONS.FORM.GET_CONTROL_PROPS, formSaga.getControlProps),
    takeEvery(SAGA_ACTIONS.FORM.SAVE, formSaga.save),
    takeEvery(SAGA_ACTIONS.FORM.GET_SOLUTION_ID, formSaga.getSolutionID),
    takeEvery(SAGA_ACTIONS.FORM.ADD_PRODUCT, formSaga.addProduct),
    takeEvery(SAGA_ACTIONS.FORM.GET_PRODUCTS_BY_SOLUTION, formSaga.getProductsBySolution),
    takeEvery(SAGA_ACTIONS.FORM.PRODUCT.DELETE, formSaga.productDelete),
    takeEvery(SAGA_ACTIONS.FORM.GET_DYNAMIC_FORM_DATA, formSaga.getDynamicFormData),
    takeEvery(SAGA_ACTIONS.FORM.UPLOAD_FILE, formSaga.uploadFile),
    takeEvery(SAGA_ACTIONS.FORM.UPLOAD_3RD_PARTY_URL, formSaga.upload3rdPartyUrl),

    takeLatest(SAGA_ACTIONS.USER.POST_USECASE_APPROVED, userSaga.postUsecaseApproved),
    takeLatest(SAGA_ACTIONS.USER.POST_USECASE_DISAPPROVED, userSaga.postUsecaseDispproved),
    takeLatest(SAGA_ACTIONS.USER.SET_CAPTAIN_CO_CAPTAIN, userSaga.setCaptainCoCaptain),
    takeLatest(SAGA_ACTIONS.USER.GET_USECASE_REVIEW_LIST, userSaga.getUsecaseReviewList),
    takeLatest(SAGA_ACTIONS.USER.USECASE_PHASE_CHANGE, userSaga.usecasePhaseChange),
    takeLatest(SAGA_ACTIONS.USER.GET_USECASE_CAPTAINS, userSaga.getUseCaseCaptains),


    takeEvery(SAGA_ACTIONS.USER.DECISION_GET, userSaga.decisionGet),
    takeEvery(SAGA_ACTIONS.USER.DECISION_SAVE, userSaga.decisionSave), 


    /* takeLatest(SAGA_ACTIONS.COMMON.SITE_MASTER, sitemasterSaga.getSiteMaster),
    takeLatest(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
    takeLatest(SAGA_ACTIONS.USER.ME, userSaga.fetchCurrentUser),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout),
    takeLatest(SAGA_ACTIONS.USER.SIGNUP, userSaga.signup),
    takeLatest(SAGA_ACTIONS.USER.MERCHANT_SIGNUP, userSaga.merchantSignup),
    takeLatest(SAGA_ACTIONS.USER.VERIFY_ACCOUNT, userSaga.verifyAccount),
    takeLatest(SAGA_ACTIONS.USER.REVERIFY_ACCOUNT, userSaga.reVerifyAccount),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_USER, userSaga.updateUser),
    takeLatest(SAGA_ACTIONS.USER.SESSIONS_LIST, userSaga.getSessionList), */


    takeLatest(SAGA_ACTIONS.USER.DISCOVERY_GET_SINGLE, userSaga.getDiscovery),
    takeLatest(SAGA_ACTIONS.USER.DISCOVERY_GET_ALL, userSaga.getAllDiscovery), 
    takeLatest(SAGA_ACTIONS.USER.DISCOVERY_DELETE, userSaga.deleteDiscoveryFile), 
    takeLatest(SAGA_ACTIONS.USER.USECASE_CHALLENGE_STATEMENT, userSaga.getChallengeStatementByUsecase),
    takeLatest(SAGA_ACTIONS.USER.USECASE_LIKE, userSaga.usecaseLike),
    takeLatest(SAGA_ACTIONS.USER.ASSIGN_INNOVATION_MANAGER_TO_USECASE, userSaga.assignInnovationManagerToUsecase),

    takeEvery(SAGA_ACTIONS.MASTER.GET_SECTIONS, masterSaga.getSections),
    takeEvery(SAGA_ACTIONS.MASTER.GET_PHASES, masterSaga.getPhases),


    takeEvery(SAGA_ACTIONS.WORKFLOW.GET_SCORING_PRIOTITIZATION_LIST, workFlowSaga.getScoringPrioritizationByUsecase),
    takeEvery(SAGA_ACTIONS.WORKFLOW.ADD_SCORING_PRIOTITIZATION, workFlowSaga.scoringPrioritizationSave),
    takeEvery(SAGA_ACTIONS.WORKFLOW.DELETE_SCORING_PRIOTITIZATION, workFlowSaga.scoringPrioritizationDelete),
    takeEvery(SAGA_ACTIONS.WORKFLOW.GET_SCORE_QUESTIONS, workFlowSaga.getScoreQuestions),
    takeEvery(SAGA_ACTIONS.WORKFLOW.SUBMIT_SCORE_QUESTIONS, workFlowSaga.submitScoreQuestions),
    takeEvery(SAGA_ACTIONS.WORKFLOW.SUBMIT_HANGER_DECISION, workFlowSaga.submitHangerDecision),
    takeEvery(SAGA_ACTIONS.WORKFLOW.APPROVE_PRE_SCORING, workFlowSaga.approvePreScoring),
    

    takeEvery(SAGA_ACTIONS.WORKFLOW.GET_ALL_PROJECT_PLAN, workFlowSaga.getAllProjectPlan),
    takeEvery(SAGA_ACTIONS.WORKFLOW.DELETE_PROJECT_PLAN, workFlowSaga.deleteProjectFile),
    takeEvery(SAGA_ACTIONS.WORKFLOW.GET_ALL_PROCUREREMENT_PLAN, workFlowSaga.getAllProcurementPlan),
    takeEvery(SAGA_ACTIONS.WORKFLOW.DELETE_PROCUREREMENT_PLAN, workFlowSaga.deleteProcurementFile),


    takeEvery(SAGA_ACTIONS.WORKFLOW.TESTING_ROLES_RESPONSIBILE.LIST_BY_USECASE, workFlowSaga.getTestingRolesResponsibilitiesByUsecase),
    takeEvery(SAGA_ACTIONS.WORKFLOW.TESTING_ROLES_RESPONSIBILE.SAVE, workFlowSaga.TestingRolesResponsibilitiesSave),
    takeEvery(SAGA_ACTIONS.WORKFLOW.TESTING_ROLES_RESPONSIBILE.DELETE, workFlowSaga.TestingRolesResponsibilitiesDelete),
    takeEvery(SAGA_ACTIONS.WORKFLOW.TESTING_ROLES_RESPONSIBILE.CRITERIA_BY_USECASE, workFlowSaga.getTestCasesAcceptanceCriteriaByUsecase),
    takeEvery(SAGA_ACTIONS.WORKFLOW.TESTING_ROLES_RESPONSIBILE.CRITERIA_DELETE, workFlowSaga.DeleteTestCasesAcceptanceCriteria),
    takeEvery(SAGA_ACTIONS.WORKFLOW.TESTING_ROLES_RESPONSIBILE.CRITERIA_ASSIGN_USER, workFlowSaga.AssignTestCasesAcceptanceCriteriaUser),
 
    takeEvery(SAGA_ACTIONS.WORKFLOW.DAY_IN_LIFE.GET_MEMBERS, workFlowSaga.getMembers),
    takeEvery(SAGA_ACTIONS.WORKFLOW.DAY_IN_LIFE.ADD_MEMBER, workFlowSaga.addMember),
    takeEvery(SAGA_ACTIONS.WORKFLOW.DAY_IN_LIFE.DELETE_MEMBER, workFlowSaga.deleteMember),
    takeEvery(SAGA_ACTIONS.WORKFLOW.UPDATE_START_DATE_END_DATE, workFlowSaga.updateStartDateEndDate),
    takeEvery(SAGA_ACTIONS.WORKFLOW.GET_START_DATE_END_DATE, workFlowSaga.getStartDateEndDate),

    takeLatest(SAGA_ACTIONS.COMPANY.LIST, userSaga.companyList),

    takeLatest(SAGA_ACTIONS.TENANT.LIST, userSaga.getTenantsAll),

    takeLatest(SAGA_ACTIONS.RUNWAY.LIST, userSaga.runwayList),
    takeLatest(SAGA_ACTIONS.RUNWAY.SAVE, userSaga.runwaySave),
    takeLatest(SAGA_ACTIONS.RUNWAY.UPDATE, userSaga.runwayUpdate),
    takeLatest(SAGA_ACTIONS.RUNWAY.DETAILS, userSaga.runwayDetails),
    takeLatest(SAGA_ACTIONS.RUNWAY.CHANGE_STATUS, userSaga.runwayChangeStatus),
    takeLatest(SAGA_ACTIONS.RUNWAY.DELETE, userSaga.runwayDelete),

 
    takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.LIST, userSaga.solutionProviderList),
    takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.SAVE, userSaga.solutionProviderSave),
    takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.UPDATE, userSaga.solutionProviderUpdate),
    takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.DETAILS, userSaga.solutionProviderDetails),
    takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.CHANGE_STATUS, userSaga.solutionProviderChangeStatus),
    takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.DELETE, userSaga.solutionProviderDelete),

    takeLatest(SAGA_ACTIONS.USER.MENU_PERMISSION.GET_LIST, userSaga.menuPermissionGetByCompanyID),
    takeLatest(SAGA_ACTIONS.USER.MENU_PERMISSION.SAVE, userSaga.menuPermissionSave),
 
    takeLatest(SAGA_ACTIONS.USER.DASHBOARD.SUMMERY_COUNT, userSaga.executiveDashboardSummeryCoun),
    takeLatest(SAGA_ACTIONS.USER.DASHBOARD.AVG_DURATION_LIST, userSaga.executiveDashboardAvgDuration),
    takeLatest(SAGA_ACTIONS.USER.DASHBOARD.PHASE_PLAN, userSaga.executiveDashboardPhasePlan),
    takeLatest(SAGA_ACTIONS.USER.DASHBOARD.PHASE_AVG, userSaga.executiveDashboardPhaseAvg),

    takeLatest(SAGA_ACTIONS.USER.DASHBOARD.AVG_TOTAL_DURATION, userSaga.periodComparisionDashboardAvgTotalDuration),
    takeLatest(SAGA_ACTIONS.USER.DASHBOARD.AVG_DURATION_BY_SECTION, userSaga.periodComparisionDashboardAvgDurationBySection),
    takeLatest(SAGA_ACTIONS.USER.DASHBOARD.SECTION_PHASE_TABLE, userSaga.periodComparisionDashboardSectionPhaseTable),
    takeLatest(SAGA_ACTIONS.USER.DASHBOARD.AVG_DURATION_PHASE, userSaga.periodComparisionDashboardAvgDurationPhase),
 
    takeLatest(SAGA_ACTIONS.NOTIFICATION.LIST, userSaga.notificationList),
    takeLatest(SAGA_ACTIONS.NOTIFICATION.DETAILS, userSaga.notificationDetails),
    takeLatest(SAGA_ACTIONS.NOTIFICATION.UPDATE, userSaga.notificationUpdate),



    takeLatest(SAGA_ACTIONS.SCORE_QUESTION.LIST, formSaga.getAllScoreQuestions),
    takeLatest(SAGA_ACTIONS.SCORE_QUESTION.SAVE, formSaga.scoreQuestionSave),
    takeLatest(SAGA_ACTIONS.SCORE_QUESTION.UPDATE, formSaga.scoreQuestionUpdate),
    takeLatest(SAGA_ACTIONS.SCORE_QUESTION.DETAILS, formSaga.getScoreQuestions),
    takeLatest(SAGA_ACTIONS.SCORE_QUESTION.CHANGE_STATUS, formSaga.scoreQuestionStatusChange),
    takeLatest(SAGA_ACTIONS.SCORE_QUESTION.DELETE, formSaga.scoreQuestionDelete),


  ])
}
